import { FC } from 'react'
import MKTypography from 'components/MaterialKit/MKTypography'
import EventNotification from 'models/Notifications/EventNotification'
import { NotificationTypeProps, NotificationTypeComponent } from './types'
import { LOCALE } from 'utils/Constants'
import EventNotificationLogo from '../Logos/EventNotificationLogo'

const Text: FC<NotificationTypeProps> = ({ notification }) => {
    const eventNotification = notification as EventNotification
    
    return (
        <MKTypography variant="body2" color="text" display="inline">
            <b>{eventNotification.$event.$name}</b> start morgen om{' '}
            <b>
                {eventNotification.$event.$start_time.toLocaleTimeString(LOCALE, {
                    hour: '2-digit',
                    minute: '2-digit',
                })}
            </b>
        </MKTypography>
    )
}

const Logo: FC<NotificationTypeProps> = ({ notification }) => {
    const eventNotification = notification as EventNotification
    return <EventNotificationLogo notification={eventNotification} />
}

export const EventReminder: NotificationTypeComponent = {
    Text,
    Logo,
} 