import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

// Material Kit 2 React components
import MKBox from 'components/MaterialKit/MKBox'

// Images
import bgImage from 'assets/images/crowd_laughing.png'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useUserContext } from 'contexts/UserContext'

export const Banner = () => {
    const { user } = useUserContext()
    return (
        <MKBox
            variant="gradient"
            bgColor="dark"
            position="relative"
            borderRadius="xl"
            sx={{ overflow: 'hidden' }}
            mb={6}
        >
            <MKBox
                component="img"
                src={bgImage}
                alt="pattern-lines"
                position="absolute"
                top={0}
                left={0}
                width="100%"
                zIndex={1}
                opacity={0.2}
            />
            <Container sx={{ position: 'relative', zIndex: 2, py: 12 }}>
                <Grid
                    container
                    item
                    xs={12}
                    md={7}
                    justifyContent="center"
                    mx="auto"
                    textAlign="center"
                >
                    <div>
                        <MKTypography variant="h3" color="white">
                            Kunnen ze bij of met
                        </MKTypography>
                        <MKTypography variant="h3" color="white" mb={1}>
                            u komen lachen?
                        </MKTypography>
                    </div>
                    <MKTypography variant="body2" color="white" mb={6}>
                        Maak een account aan en ontdek de mogelijkheden van ComedyKit! Maakt niet
                        uit of je een beginner bent, of een doorwinterde comedian. ComedyKit is er
                        voor iedereen!
                    </MKTypography>
                    <MKButton
                        variant="gradient"
                        color="info"
                        size="large"
                        component="a"
                        href={
                            user
                                ? '/?lineup=spots_available'
                                : '/register'
                        }
                        sx={{ mb: 2 }}
                    >
                        {user ? 'Vind een spot!' : 'Maak een account aan!'}
                    </MKButton>
                </Grid>
            </Container>
        </MKBox>
    )
}
