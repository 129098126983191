/* eslint-disable unused-imports/no-unused-vars */
import { isPlatform } from '@ionic/react'
import DirectionsIcon from '@mui/icons-material/Directions'
import { Popover } from '@mui/material'
import { GoogleMap, Marker } from '@react-google-maps/api'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import Address from 'models/Address'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import './style.scss'

interface Coordinates {
    lat: number
    lng: number
}

interface GoogleMapsComponentProps {
    selected?: Coordinates | null
    defaultValue?: string
    types?: string[]
    className?: string
    disabled?: boolean
    label?: string
    placeholder?: string
    error?: string
    InputProps?: any
    onSelect: (address: Address) => void
    showNavigate?: boolean
    hideMap?: boolean
    disableFullWidth?: boolean
    mapOnly?: boolean
    to?: string,
    address?: Address
}

const GoogleMapsComponent: FC<GoogleMapsComponentProps> = ({
    selected: initialSelected,
    defaultValue,
    types = ['geocode', 'establishment'],
    className,
    disabled,
    label,
    placeholder,
    error,
    InputProps,
    onSelect,
    showNavigate,
    hideMap,
    disableFullWidth,
    mapOnly,
    to,
    address,
}) => {
    const [selected, setSelected] = useState<Coordinates | null>(initialSelected || null)
    const [inputValue, setInputValue] = useState(defaultValue || '')
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
    const [zoom, setZoom] = useState(7)

    const center = useMemo(() => selected || { lat: 50.4991878, lng: 3.8054614 }, [selected])

    useEffect(() => {
        setInputValue(defaultValue || '')
    }, [defaultValue])

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types,
            componentRestrictions: { country: 'be' },
        },
    })

    useEffect(() => {
        if (initialSelected) {
            setSelected(initialSelected)
            setZoom(20)
        }
    }, [initialSelected])

    const extractFromAddress = (address, type) => {
        const addrComps = address.address_components
        for (const comp of addrComps) {
            if (comp.types.includes(type)) {
                return comp['long_name']
            }
        }
    }

    const onLocationSelect = useCallback(
        async (address: string) => {
            setValue(address, false)
            clearSuggestions()
            setInputValue(address)

            const results = await getGeocode({ address })
            const { lat, lng } = await getLatLng(results[0])
            const city = extractFromAddress(results[0], 'locality')
            const street_number = extractFromAddress(results[0], 'street_number')
            const street = extractFromAddress(results[0], 'route')
            const postal_code = extractFromAddress(results[0], 'postal_code')
            const region = extractFromAddress(results[0], 'administrative_area_level_2')
    
            const coordinates = { lat, lng }
            const _address = new Address(
                lat,
                lng,
                street,
                region,
                postal_code,
                street_number,
                city,
                address
            )

            setSelected(coordinates)
            setZoom(20)
            onSelect(_address)
        },
        [clearSuggestions, onSelect, setValue]
    )

    const dataFilter = useCallback((a: any) => types.includes(a.types[0]), [types])

    return (
        <div className={className}>
            {!disabled && inputValue !== undefined && (
                <>
                    <MKInput
                        error={error}
                        label={label}
                        value={inputValue}
                        placeholder={placeholder}
                        onChange={(e) => {
                            setAnchorEl(e.currentTarget)
                            setInputValue(e.target.value)
                            if (!e.target.value) {
                                onSelect(null)
                            }

                            if (inputTimeout) {
                                clearTimeout(inputTimeout)
                                setInputTimeout(undefined)
                            }
                            setInputTimeout(setTimeout(() => setValue(e.target.value), 500))
                        }}
                        InputProps={InputProps}
                        InputLabelProps={{ shrink: true }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{error}</CKFormFeedback>

                    <Popover
                        open={Boolean(
                            anchorEl && status === 'OK' && data.filter(dataFilter).length > 0
                        )}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        onClose={() => setAnchorEl(null)}
                        disableAutoFocus
                        disableEnforceFocus
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        <div className="bg-white">
                            {data.filter(dataFilter).map((result: any) => (
                                <div key={result.place_id}>
                                    <MKButton
                                        onClick={() => onLocationSelect(result.description)}
                                        variant="text"
                                        color="info"
                                        className="px-2"
                                    >
                                        {result.description}
                                    </MKButton>
                                </div>
                            ))}
                        </div>
                    </Popover>
                </>
            )}

            {disabled && !mapOnly && (
                <div
                    className={`text-center ${to ? 'ck-address-btn' : ''}`}
                    onClick={() => to && history.push(to)}
                >
                    <MKTypography fontWeight="bold" variant="label" color="info">
                        {label}
                    </MKTypography>
                    <div className="mt-1">
                        <MKTypography variant="button" fontWeight="bold">
                            {address?.$name}
                        </MKTypography>
                        <br />
                        <MKTypography variant="button">
                            {address?.$street} {address?.$streetNumber}
                        </MKTypography>
                        <br />
                        <MKTypography variant="button">
                            {address?.$postalCode} {address?.$city}
                        </MKTypography>
                        <br />
                    </div>
                </div>
            )}

            {showNavigate && (
                <MKButton
                    variant="gradient"
                    color="info"
                    className="mt-4 mb-2"
                    onClick={(e) => {
                        e.preventDefault()
                        const url = isPlatform('ios')
                            ? `maps://maps.apple.com/q=${selected?.lat},${selected?.lng}`
                            : isPlatform('android')
                              ? `geo:0,0?q=${selected?.lat},${selected?.lng}`
                              : `https://www.google.com/maps/dir//${inputValue.replace(' ', '+')}/@${selected?.lat},${selected?.lng},17z`
                        window.open(url, '_system')
                    }}
                >
                    <DirectionsIcon className="me-2" /> Navigeer
                </MKButton>
            )}

            {!hideMap && (
                <div
                    className={disabled ? 'mt-3' : 'mt-5'}
                    style={{
                        width: disableFullWidth ? '100%' : 'calc(100% + 96px)',
                        marginLeft: disableFullWidth ? '0px' : '-48px',
                        height: '400px',
                        borderRadius: '0.65rem',
                    }}
                >
                    <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container">
                        {selected && (
                            <Marker position={{ lat: selected.lat, lng: selected.lng }} />
                        )}
                    </GoogleMap>
                </div>
            )}
        </div>
    )
}

interface CKAddressPickerProps {
    address?: Address
    InputProps?: any
    types?: string[]
    className?: string
    showNavigate?: boolean
    disabled?: boolean
    label?: any
    placeholder?: string
    error?: string
    mapOnly?: boolean
    defaultValue?: string
    to?: string
    disableFullWidth?: boolean
    hideMap?: boolean
    onSelect?: (address: Address) => void
}

const CKAddressPicker: FC<CKAddressPickerProps> = ({
    address,
    InputProps,
    types,
    className,
    showNavigate,
    disabled,
    label,
    placeholder,
    error,
    mapOnly,
    defaultValue,
    to,
    disableFullWidth,
    hideMap,
    onSelect,
}) => {
    return (
        <GoogleMapsComponent
            InputProps={InputProps}
            types={types ?? ['geocode', 'establishment']}
            className={className}
            showNavigate={showNavigate}
            disabled={disabled}
            label={label}
            placeholder={placeholder}
            error={error}
            address={address}
            mapOnly={mapOnly}
            defaultValue={defaultValue ?? address?.$fullAddress}
            to={to}
            disableFullWidth={disableFullWidth}
            hideMap={hideMap}
            selected={{ lat: address?.$lat ?? 50.4991878, lng: address?.$lng ?? 3.8054614 }}
            onSelect={onSelect}
        />
    )
}

export default CKAddressPicker
