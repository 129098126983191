import Event from 'models/Event'
import User from 'models/User'
import useSWR from 'swr'
import AuthService, { ckFetcher, useSWRPaginated } from './AuthService'
import { EVENTS } from './Constants';
import { useMqtt } from 'contexts/MqttContext';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom'
import ImageService from './ImageService';

export function useEvents(
    page: number = 1,
    perPage: number = 10,
    search: string = '',
    locationSearch: { lat?: number; lng?: number; radius?: number } = undefined,
    lineupFilters: { lineupComplete?: boolean; spotsAvailable?: boolean } = undefined
) {
    let url = `${EVENTS}?page=${page}&per_page=${perPage}`
    if (search) {
        url += `&search=${search}`
    }
    if (locationSearch && locationSearch.lat && locationSearch.lng && locationSearch.radius) {
        url += `&location=${locationSearch.lat},${locationSearch.lng},${locationSearch.radius}`
    }

    if (lineupFilters) {
        let lineupFilterValue = 'all'
        if (lineupFilters.spotsAvailable && !lineupFilters.lineupComplete) {
            lineupFilterValue = 'spots_available'
        } else if (!lineupFilters.spotsAvailable && lineupFilters.lineupComplete) {
            lineupFilterValue = 'complete'
        }

        url += `&lineup=${lineupFilterValue}`
    }

    const { data, ...props } = useSWRPaginated(url)

    const _events: Event[] = []
    if (data)
        for (const e of data) {
            _events.push(Event.fromJSON(e))
        }

    return {
        events: _events,
        ...props,
    }
}

export function useEvent(slug: string) {
    const { data, ...props } = useSWR(`${EVENTS}/${slug}`, ckFetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    if (!data) return { event: null, ...props }

    const event = Event.fromJSON(data)
    return {
        event,
        ...props,
    }
}

export function useEventEdit(slug: string) {
    const [event, setEvent] = useState<Event | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<any>({})

    const [logoData, setLogoData] = useState<any>()
    const [bannerData, setBannerData] = useState<any>()

    const [saving, setSaving] = useState<boolean>(false)
    const history = useHistory()
    const { mutate } = useEvent(slug)

    useMemo(() => {
        if (slug) {
            if (slug === 'new') {
                setEvent(new Event())
                setLoading(false)
            } else {
                EventService.get(slug).then((e) => {
                    setEvent(e)
                    setLoading(false)
                });
            }
        }
    }, [slug])
    

    const memoizedValue = useMemo(() => {
        const validate = () => {
            const _errors = {}

            const defaultMsg = 'Verplicht veld!'

            _errors['name'] = !event.$name ? defaultMsg : false
            _errors['date'] = !event.$start_time ? defaultMsg : false
            _errors['start_time'] = !event.$start_time ? defaultMsg : false
            _errors['arrival_time'] = !event.$arrival_time ? defaultMsg : false
            if (event.$start_time && event.$arrival_time && event.$start_time < event.$arrival_time) {
                _errors['arrival_time'] = 'Ongeldige aankomsttijd'
            }
            _errors['location'] = !event.$location ? defaultMsg : false

            setErrors(_errors)
            return !Object.values(_errors).find((a) => a !== false)
        }

        const save = async () => {
            setSaving(true)
            try {
                const validated = validate()
                if (validated) {
                    if (logoData) {
                        event.$logo = await ImageService.upload({
                            base64: logoData.content,
                            extension: logoData.ext,
                            name: event.$name + ' - LOGO',
                            type: 'LOGO',
                        })
                    }

                    if (bannerData) {
                        event.$banner = await ImageService.upload({
                            base64: bannerData.content,
                            extension: bannerData.ext,
                            name: event.$name + ' - BANNER',
                            type: 'BANNER',
                        })
                    }

                    if (event.$custom_lineup) {
                        for (const spot of event.$custom_lineup) {
                            if (spot.$pictureData) {
                                spot.$picture = await ImageService.upload({
                                    base64: spot.$pictureData.content,
                                    extension: spot.$pictureData.ext,
                                    name: `Custom lineup - ${spot.$name} - LOGO`,
                                    type: 'LOGO',
                                })
                            }
                        }
                    }

                    // if (ticketConfig.current) event.$tickets = ticketConfig.current.getTickets()

                    let _event
                    if (slug === 'new') {
                        _event = await EventService.post(event)
                    } else {
                        _event = await EventService.update(event)
                        await mutate()
                    }
                    history.push(_event.$detailUrl)
                }
            } catch (e) {
                console.error(e)
            }
            setSaving(false)
        }

        return { event, loading, setLogoData, setBannerData, save, saving, errors };

    }, [event, loading, setLogoData, setBannerData, saving, bannerData, logoData, history, errors, mutate, slug])

    return memoizedValue
}

export function useEventTicketStatistics(event: Event) {
    const mqtt = useMqtt()

    const [amountScanned, setAmountScanned] = useState<{ [key: string]: number }>({})

    if (event)
        mqtt.subscribeTo(`event/${event.$slug}/ticket_statistics`, (msg) => {
            console.log('msg', msg)
            setAmountScanned(msg);
        })


    const memoizedValue = useMemo(() => {
        return { ticketScanStatistics: amountScanned };
    }, [amountScanned])
    return memoizedValue
}

const EventService = {
    get: async function (slug: string) {
        const resp = await AuthService.get(`${EVENTS}/${slug}`)

        const event = Event.fromJSON(resp.data)
        return event
    },
    update: async function (event: Event) {
        const resp = await AuthService.put(`${EVENTS}/${event.$slug}`, event)

        const e = Event.fromJSON(resp.data)
        return e
    },
    post: async function (event: Event) {
        const resp = await AuthService.post(`${EVENTS}`, event)

        const e = Event.fromJSON(resp.data.data)
        return e
    },
    index: async function (
        page: number = 1,
        perPage: number = 10,
        search: string = '',
        location: { lat?: number; lng?: number; radius?: number } = undefined
    ) {
        const resp = await AuthService.get(EVENTS, {
            per_page: perPage,
            page: page,
            search: search,
            location:
                Boolean(location && location.lat && location.lng && location.radius) &&
                `${location.lat},${location.lng},${location.radius}`,
        })
        const result: Event[] = []

        for (const e of resp.data.data) {
            result.push(Event.fromJSON(e))
        }

        resp.data.data = result
        return resp.data
    },
    getApplications: async function (
        event: Event,
        page: number = 1,
        search: string = '',
        perPage: number = 50
    ) {
        const resp = await AuthService.get(
            `${EVENTS}/${event.$slug}/applications?per_page=${perPage}&page=${page}&search=${search}`
        )
        const result: User[] = []

        for (const e of resp.data) {
            result.push(User.fromJSON(e))
        }

        resp.data = result
        return resp.data
    },
    saveLineup: async function (
        event: Event,
        isComplete: boolean,
        data: { event_spot_id: number; user_id: number }[]
    ) {
        const resp = await AuthService.put(`${EVENTS}/${event.$slug}/lineup`, {
            is_complete: isComplete,
            line_up: data,
        })
        return resp.data
    },
}
export default EventService
