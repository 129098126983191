import React, { createContext, useContext, useCallback } from 'react'
import { useNotifications, useUnreadNotificationsCount } from '../hooks/useNotifications'
import NotificationService from '../services/NotificationService'
import Notification from '../models/Notifications/Notification'

interface NotificationsContextType {
    notifications: Notification[]
    unreadCount: number | undefined
    isLoading: boolean
    hasMorePages: boolean
    loadMore: () => void
    markAsRead: (notification: Notification) => Promise<void>
    markAsUnread: (notification: Notification) => Promise<void>
    deleteNotification: (notification: Notification) => Promise<void>
    markAllAsRead: () => Promise<void>
    refresh: () => Promise<void>
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined)

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { 
        notifications, 
        isLoading, 
        fetchNextPage, 
        hasMorePages,
        mutate 
    } = useNotifications()
    const { unreadCount, mutate: mutateUnreadCount } = useUnreadNotificationsCount()

    const markAsRead = useCallback(async (notification: Notification) => {
        await NotificationService.markAsRead(notification)
        await Promise.all([
            mutate(),
            mutateUnreadCount()
        ])
    }, [mutate, mutateUnreadCount])

    const markAsUnread = useCallback(async (notification: Notification) => {
        await NotificationService.markAsUnread(notification)
        await Promise.all([
            mutate(),
            mutateUnreadCount()
        ])
    }, [mutate, mutateUnreadCount])

    const deleteNotification = useCallback(async (notification: Notification) => {
        await NotificationService.delete(notification)
        await Promise.all([
            mutate(),
            mutateUnreadCount()
        ])
    }, [mutate, mutateUnreadCount])

    const markAllAsRead = useCallback(async () => {
        await NotificationService.markAllAsRead()
        await Promise.all([
            mutate(),
            mutateUnreadCount()
        ])
    }, [mutate, mutateUnreadCount])

    const refresh = useCallback(async () => {
        await Promise.all([
            mutate(),
            mutateUnreadCount()
        ])
    }, [mutate, mutateUnreadCount])

    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                unreadCount,
                isLoading,
                hasMorePages,
                loadMore: fetchNextPage,
                markAsRead,
                markAsUnread,
                deleteNotification,
                markAllAsRead,
                refresh,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    )
}

export const useNotificationsContext = () => {
    const context = useContext(NotificationsContext)
    if (!context) {
        throw new Error('useNotificationsContext must be used within a NotificationsProvider')
    }
    return context
} 