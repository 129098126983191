import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { isPlatform } from '@ionic/react'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import MKButton from 'components/MaterialKit/MKButton'
import { useDarkMode } from 'contexts/DarkModeContext'
import { FC, useState } from 'react'
import './style.scss'

interface Props {
    onSelect: (native: string) => void
}
const CKEmojiPicker: FC<Props> = (props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const darkMode = useDarkMode()

    return (
        <>
            {!isPlatform('ios') && !isPlatform('android') && (
                <>
                    <MKButton
                        variant="outlined"
                        className="ck-chat-btn"
                        color="secondary"
                        onClick={(e) => {
                            setAnchorEl(anchorEl ? null : e.currentTarget)
                        }}
                        size="large"
                        iconOnly
                        circular
                    >
                        <EmojiEmotionsIcon />
                    </MKButton>

                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        className="ck-emoji-popper"
                        onClose={() => {
                            setAnchorEl(undefined)
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                    >
                        {/* <ClickAwayListener onClickAway={(e) => {
					if (anchorEl)
						setAnchorEl(null)
				}}> */}
                        <Typography>
                            <Picker
                                data={data}
                                theme={darkMode.dark ? 'dark' : 'light'}
                                onEmojiSelect={(e) => {
                                    props.onSelect(e.native)
                                }}
                            />
                        </Typography>
                        {/* </ClickAwayListener> */}
                    </Popover>
                </>
            )}
        </>
    )
}
export default CKEmojiPicker
