import { NotificationType } from 'enums/NotificationType'
import Event from 'models/Event'
import Image from 'models/Image'
import Notification from './Notification'

interface EventNotificationProps {
    id?: string
    type?: NotificationType
    title?: string
    message?: string
    read?: boolean
    created_at?: Date
    event?: Event
    location_name?: string
    city?: string
}

export default class EventNotification extends Notification {
    private event: Event
    private location_name: string
    private city: string

    constructor({
        id,
        type,
        title,
        message,
        read,
        created_at,
        event,
        location_name,
        city,
    }: EventNotificationProps = {}) {
        super({ id, type, title, message, read, created_at })
        this.event = event
        this.location_name = location_name
        this.city = city
    }

    public static fromJSON(json: {
        id: string
        type: string
        data: { title: string; message: string }
        read_at: string | null
        created_at: string
        extra_data?: {
            event?: {
                id: string
                name: string
                start_time: string
                slug: string
                logo: any
                location_name?: string
                location_city?: string
            }
        }
    }): EventNotification {
        const logo = json.extra_data?.event?.logo
            ? new Image({
                  id: json.extra_data.event.logo.id,
                  name: json.extra_data.event.logo.name,
                  url: json.extra_data.event.logo.url,
              })
            : undefined

        const event = new Event({
            id: json.extra_data?.event?.id,
            name: json.extra_data?.event?.name,
            start_time: new Date(json.extra_data?.event?.start_time),
            slug: json.extra_data?.event?.slug,
            logo: logo,
        })

        return new EventNotification({
            id: json.id,
            type: json.type as NotificationType,
            title: json.data.title,
            message: json.data.message,
            read: !!json.read_at,
            created_at: new Date(json.created_at),
            event: event,
            location_name: json.extra_data?.event?.location_name,
            city: json.extra_data?.event?.location_city,
        })
    }

    public override get $url(): string {
        return this.event.$detailUrl
    }

    public get $event(): Event {
        return this.event
    }

    public get $locationName(): string {
        return this.location_name
    }

    public get $city(): string {
        return this.city
    }
}
