export enum NotificationType {
    EVENT_CHANGES = 'event_changes',
    EVENT_NEARBY = 'event_nearby',
    EVENT_REMINDER = 'event_reminder',
    LINEUP = 'lineup'
}

export function isEventRelated(type: NotificationType): boolean {
    switch (type) {
        case NotificationType.EVENT_CHANGES:
        case NotificationType.EVENT_NEARBY:
        case NotificationType.EVENT_REMINDER:
        case NotificationType.LINEUP:
            return true;
        default:
            return false;
    }
}