import Event from "models/Event"

export const ACCESS = 'access'
export const ARTISTS = 'artists'
export const AUTH = 'auth'
export const BUG_REPORTS = 'bug-reports'
export const CHATS = 'chats'
export const COMEDY_TAGS = 'comedyTags'
export const DEVICES = 'devices'
export const EVENTS = 'events'
export const IMAGES = 'images'
export const LOCATIONS = 'locations'
export const ME = 'me'
export const NOTIFICATIONS = 'notifications'
export const ORGANISATIONS = 'organisations'
export const SPOTS = 'spots'
export const STATISTICS = 'statistics'
export const TICKETS = 'tickets'
export const UPCOMING_EVENTS = 'upcoming-events'
export const USERS = 'users'
export const WORKSHOPS = 'workshops'

export const MY_EVENT_TICKETS_ROUTE = (event: Event) => `${EVENTS}/${event.$slug}/my-tickets`
