import { useState, useEffect, useRef } from 'react'
import useSWR from 'swr'
import { useSWRPaginated } from '../services/AuthService'
import { NOTIFICATIONS } from '../services/Constants'
import Notification from '../models/Notifications/Notification'
import { createNotificationFromJSON } from '../models/Notifications/NotificationFactory'
import { toast } from 'react-toastify'

export function useNotifications(initialPage = 1) {
    const [page, setPage] = useState(initialPage)
    const [notifications, setNotifications] = useState<Notification[]>([])
    const { 
        data, 
        paginationInfo, 
        mutate,
        ...props 
    } = useSWRPaginated(`${NOTIFICATIONS}?page=${page}`)

    useEffect(() => {
        if (!data) return

        setNotifications(prevNotifications => {
            const newNotifications = data.map(json => createNotificationFromJSON(json))
            return page === 1 ? newNotifications : [...prevNotifications, ...newNotifications]
        })
    }, [data, page])

    const fetchNextPage = () => setPage(prev => prev + 1)
    const hasMorePages = page < (paginationInfo?.last_page || 0)

    return {
        notifications,
        fetchNextPage,
        page,
        paginationInfo,
        hasMorePages,
        isLoading: !data && !props.error,
        mutate,
        ...props,
    }
}

export function useUnreadNotificationsCount() {
    const [previousCount, setPreviousCount] = useState<number>(0)
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const isAutoRefreshRef = useRef(false)
    
    useEffect(() => {
        const timer = setInterval(() => {
            isAutoRefreshRef.current = true
        }, 60000)
        
        return () => clearInterval(timer)
    }, [])
    
    const { data: unreadCount, mutate, ...props } = useSWR<number>(
        `${NOTIFICATIONS}/unread-count`,
        {
            refreshInterval: 60000,
            revalidateOnFocus: false,
            onSuccess: (newCount) => {
                if (isInitialLoad) {
                    setIsInitialLoad(false)
                    setPreviousCount(newCount)
                    return
                }

                if (newCount > previousCount && isAutoRefreshRef.current) {
                    const newNotifications = newCount - previousCount
                    const message = newNotifications === 1 
                        ? 'Je hebt 1 nieuwe melding'
                        : `Je hebt ${newNotifications} nieuwe meldingen`
                    
                    toast.info(message, {
                        position: "top-right",
                        containerId: 'B'
                    })
                }
                
                isAutoRefreshRef.current = false
                setPreviousCount(newCount)
            }
        }
    )
    
    return {
        unreadCount,
        mutate,
        ...props,
    }
} 