import { useMqtt } from 'contexts/MqttContext'
import ChatMessage from 'models/ChatMessage'
import ChatRoom from 'models/ChatRoom'
import { useEffect, useMemo, useRef, useState } from 'react'
import AuthService, { addSearchParams, useSWRPaginated } from './AuthService'
import { CHATS } from './Constants'

export function useChatMessages(room: ChatRoom, onNewMessage?: () => void) {
    const mqtt = useMqtt()

    const perPage = 30
    const [page, setPage] = useState(1)
    const {
        messages: messagePage,
        isLoading,
        initialized,
        amountPages,
    } = useChatMessagesInit(room, page, perPage)
    const messagesRef = useRef([])
    const [receivedMessages, setReceivedMessages] = useState<ChatMessage[]>([])
    const initializedRef = useRef(false)

    // const [amountPages, setAmountPages] = useState(1)

    useEffect(() => {
        if (room && mqtt) {
            mqtt.subscribeTo(`chat/${room.$slug}`, (msg) => {
                const chatMsg = ChatMessage.fromJSON(msg)
                setReceivedMessages((prev) => [...prev, chatMsg])

                if (onNewMessage) {
                    onNewMessage()
                }

                ChatService.markAsRead(room)
            })
        }
        return () => {
            if (room) {
                mqtt.unsubscribe(`chat/${room.$slug}`)
            }
        }
    }, [room, mqtt, onNewMessage])

    useMemo(() => {
        if (initialized && !initializedRef.current) {
            setTimeout(() => {
                initializedRef.current = true
                if (onNewMessage) onNewMessage()
            }, 200)
        }
    }, [onNewMessage, initialized])

    const memoizedValue = useMemo(() => {
        messagesRef.current = [...messagePage, ...messagesRef.current, ...receivedMessages].filter(
            (value, index, self) => index === self.findIndex((t) => t.$id === value.$id)
        )

        const fetchOlderMessages = async () => {
            if (
                initializedRef.current &&
                messagesRef.current.length >= perPage &&
                !isLoading &&
                amountPages > page
            ) {
                setPage((prev) => prev + 1)
            }
        }

        return { messages: messagesRef.current, fetchOlderMessages }
    }, [messagePage, receivedMessages, isLoading, amountPages, perPage, page, initializedRef])
    return memoizedValue
}

const useChatMessagesInit = (room: ChatRoom, page, perPage = 30) => {
    const url = addSearchParams(`${CHATS}/${room.$slug}/messages`, { page, perPage })
    const { data, paginationInfo, ...props } = useSWRPaginated(url)

    let messages: ChatMessage[] = []
    let amountPages = 1
    let initialized = false
    if (data) {
        initialized = true
        messages = data.map((m) => ChatMessage.fromJSON(m))
        messages = messages.reverse()
        amountPages = paginationInfo.last_page
    }

    return { messages, amountPages, initialized, ...props }
}

export const useChatRooms = (search?: string) => {
    const perPage = 12
    const [page, setPage] = useState(1)
    const url = addSearchParams(CHATS, { page, perPage, search })
    const { data, isLoading, ...props } = useSWRPaginated(url)
    const amountPages = props.paginationInfo?.last_page
    const roomsRef = useRef([])

    const memoizedValue = useMemo(() => {
        const fetchNextPage = async () => {
            if (page * perPage > roomsRef.current.length && !isLoading && page < amountPages) {
                setPage((prev) => prev + 1)
            }
        }
        if (data) {
            roomsRef.current = [
                ...roomsRef.current,
                ...data.map((r) => ChatRoom.fromJSON(r)),
            ].filter(
                (value, index, self) => index === self.findIndex((t) => t.$slug === value.$slug)
            )
        }
        return { rooms: roomsRef.current, isLoading, fetchNextPage }
    }, [data, amountPages, isLoading, page, perPage])

    return memoizedValue
}

const ChatService = {
    getMyRooms: async function (page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(`${CHATS}`, {
            per_page: perPage,
            page: page,
        })

        const result: ChatRoom[] = []
        for (const r of resp.data.data) {
            result.push(ChatRoom.fromJSON(r))
        }

        resp.data.data = result
        return resp.data
    },
    getChatRoom: async function (slug: string) {
        const resp = await AuthService.get(`${CHATS}/${slug}`)
        const result = ChatRoom.fromJSON(resp.data)

        return result
    },
    getMessages: async function (room: ChatRoom, page: number = 1, perPage: number = 30) {
        const resp = await AuthService.get(`${CHATS}/${room.$slug}/messages`, {
            page: page,
            per_page: perPage,
        })

        const result: ChatMessage[] = []
        for (const m of resp.data.data) {
            result.push(ChatMessage.fromJSON(m))
        }

        resp.data.data = result.reverse()
        return resp.data
    },
    sendMessage: async function (room: ChatRoom, content: string) {
        const resp = await AuthService.post(`${CHATS}/${room.$slug}/messages`, {
            content: content,
        })

        return resp.data
    },
    markAsRead: async function (room: ChatRoom) {
        const resp = await AuthService.get(`${CHATS}/${room.$slug}/read`)
        return resp.data
    },
}
export default ChatService
