import { Card, Collapse, Container, Grid, InputAdornment, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'
import CKSearchBar from 'components/Form/CKSearchBar'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBody, CKPageContent } from 'components/UI/CKPageContent'
import { FC, Fragment, useState } from 'react'

import PlaceIcon from '@mui/icons-material/Place'
import TuneIcon from '@mui/icons-material/Tune'

import clsx from 'clsx'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKDatePicker from 'components/Form/CKDatePicker'
import CKSelect from 'components/Form/CKSelect'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKWorkshopCompactCard from 'components/UI/CKWorkshopCard/CKWorkshopCompactCard'
import CKPagination from 'components/UI/CKPagination'
import Workshop from 'models/Workshop'
import { useWorkshops } from 'services/WorkshopService'
import BeatLoader from 'react-spinners/BeatLoader'
import Address from 'models/Address'

interface Props { }
const PageWorkshopOverview: FC<Props> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    // const [viewMode, setViewMode] = useState('grid')

    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [filtersOpen, setFiltersOpen] = useState(false)
    const [filters, setFilters] = useState({ includes: ['mc', 'openmicer'] })

    const [locationFilter, setLocationFilter] = useState<{ lat?, lng?, radius?}>({});
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')


    const { workshops, paginationInfo, isLoading } = useWorkshops({
        page,
        search: searchTerm,
        start, end,
        location: Object.values(locationFilter).join(',')
    })

    const renderFilters = () => {
        const distances = [3, 5, 10, 15, 25, 50, 75]

        return (
            <>
                <MKBox component="div" sx={{ mt: 4 }} className="d-flex  mb-3">
                    <div className="pe-3 w-100">
                        <CKSearchBar
                            onSearch={(e) => {
                                setSearchTerm(e)
                            }}
                        />
                    </div>
                    <MKButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => {
                            setFiltersOpen((prev) => !prev)
                        }}
                    >
                        <TuneIcon className={clsx({ 'me-2': !isMobile })} />{' '}
                        {!isMobile && 'Filters'}
                    </MKButton>
                </MKBox>
                <Collapse in={filtersOpen} sx={{ pb: 4 }}>
                    <Card sx={{ p: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    In de buurt van:
                                </MKTypography>
                                <Grid
                                    container
                                    columnSpacing={3}
                                    className="d-flex justify-content-center align-items-end"
                                    style={{ height: '45px' }}
                                >
                                    <Grid item xs={8}>
                                        <CKAddressPicker
                                            className={'w-100'}
                                            onSelect={(address: Address) => {
                                                if (!address.$lat || !address.$lng) return

                                                let radius = locationFilter?.radius;
                                                if (!radius) {
                                                    radius = 50;
                                                }

                                                setLocationFilter({ lat: address.$lat, lng: address.$lng, radius });
                                            }}
                                            placeholder="Locatie"
                                            hideMap={true}
                                            types={['locality']}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PlaceIcon fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CKSelect
                                            variant="standard"
                                            options={distances.map((a) => {
                                                return {
                                                    value: a,
                                                    label: '< ' + a + 'km',
                                                }
                                            })}
                                            defaultValue={
                                                locationFilter?.radius ?? 50
                                            }
                                            onSelect={(e) => {
                                                const value: number =
                                                    typeof e === 'string' ? parseInt(e) : e

                                                setLocationFilter((prev) => {
                                                    return { ...prev, radius: value }
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    Tussen:
                                </MKTypography>
                                <Grid
                                    container
                                    columnSpacing={3}
                                    style={{ height: '45px' }}
                                    className="d-flex justify-content-center align-items-end"
                                >
                                    <Grid item xs={6}>
                                        <CKDatePicker
                                            label={'Startdatum'}
                                            onChange={(e: Date) => {
                                                if (e) setStart(e.toDateString())
                                                else setStart(undefined)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CKDatePicker
                                            label={'Einddatum'}
                                            onChange={(e) => {
                                                if (e) setEnd(e.toDateString())
                                                else setEnd(undefined)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Collapse>
            </>
        )
    }

    return (
        <>
            <CKHero
                bgImage={isMobile ? bgImage2 : bgImage}
                title={'Workshops'}
                size="md"
                description={'Breng je comedy skills naar een hoger niveau!'}
            />
            <CKPageContent>
                <CKPageBody>
                    <Container>
                        {renderFilters()}
                        <div style={{ minHeight: !isMobile && '900px' }}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                            >
                                {isLoading ? (
                                    <div className="w-100 d-flex justify-content-center align-items-center flex-column py-5 h-100">
                                        <BeatLoader loading={true} color={'#1A73E8'} />
                                        <MKTypography fontWeight="bold" className="mt-2">
                                            Laden...
                                        </MKTypography>
                                    </div>
                                ) : (<>
                                    {!workshops ||
                                        (workshops.length === 0 && (
                                            <div
                                                className={clsx(
                                                    'd-flex justify-content-center align-items-center w-100 pt-3',
                                                    { 'pt-3': isMobile }
                                                )}
                                            >
                                                <MKTypography fontWeight="bold" className="mt-2">
                                                    Geen workshops gevonden met de opgegeven
                                                    criteria...
                                                </MKTypography>
                                            </div>
                                        ))}
                                    {workshops.map((workshop: Workshop) => {
                                        return (
                                            <Fragment key={'user-card-' + workshop.$id}>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <CKWorkshopCompactCard
                                                        workshop={workshop}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        )
                                    })}
                                </>
                                )}
                            </Grid>
                        </div>
                        <CKPagination info={paginationInfo} onSelect={setPage} />
                    </Container>
                </CKPageBody>
            </CKPageContent>
        </>
    )
}
export default PageWorkshopOverview
