import { isPlatform } from '@ionic/react'
import SendIcon from '@mui/icons-material/Send'
import clsx from 'clsx'
import CKEmojiPicker from 'components/Buttons/CKEmojiPicker'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { useUserContext } from 'contexts/UserContext'
import ChatRoom from 'models/ChatRoom'
import { FC, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ChatService, { useChatMessages } from 'services/ChatService'
import { useIsMobile } from 'utils/ViewportUtils'
import './style.scss'

interface Props {
    room: ChatRoom
}

const CKChatBox: FC<Props> = (props) => {
    // const [messages, setMessages] = useState<ChatMessage[]>([])
    /**
     * Scroll to the bottom of the chatwindow
     */
    const scrollToBottom = () => {
        setTimeout(() => {
            if (scrollContainer.current !== null)
                scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight
        }, 100)
    }

    const { messages, fetchOlderMessages } = useChatMessages(props.room, () => {
        scrollToBottom()
    })
    const [content, setContent] = useState('')
    const userContext = useUserContext()
    const scrollContainer = useRef(null)
    const isMobile = useIsMobile()

    const chatHeader = (
        <div className="ck-chat-box-header">
            <img alt="room-img" src={props.room.$icon.$url} />
            <h2>{props.room.$name}</h2>
        </div>
    )

    const renderBubble = (m, index) => {
        const isIncoming = m.$user.$id !== userContext.user.$id
        const showSenderInfo = index === 0 || messages[index - 1].$user.$id !== m.$user.$id
        return (
            <div
                key={'chat-message-' + m.$id}
                className={clsx(
                    'ck-chat-message-container',
                    { 'sent-message': !isIncoming },
                    { 'received-message': isIncoming },
                    { 'mt-3': showSenderInfo }
                )}
            >
                <div className={'ck-chat-user-bubble'}>
                    {showSenderInfo && (
                        <>
                            <img src={m.$user.$picture.$url} />
                            {m.$user.$first_name}
                        </>
                    )}
                </div>
                <div className="ck-chat-message-content">{m.$content}</div>
            </div>
        )
    }

    const renderBotMessage = (m, index) => {
        return (
            <div key={'chat-message-' + m.$id} className="ck-chat-bot-message">
                {m.$content}
            </div>
        )
    }

    const renderConversation = () => {
        return (
            <PerfectScrollbar
                className="ck-chat-conversation-container px-3"
                style={{ height: '100vh', width: '100%' }}
                containerRef={(ref) => {
                    scrollContainer.current = ref
                }}
                onYReachStart={fetchOlderMessages}
            >
                {messages.map((m, index) => {
                    if (m.$isNotification) {
                        return renderBotMessage(m, index)
                    } else {
                        return renderBubble(m, index)
                    }
                })}
            </PerfectScrollbar>
        )
    }

    const sendMessage = async () => {
        if (!content) return

        await ChatService.sendMessage(props.room, content)
        setContent('')
    }

    /**
     * Event handler on chat input
     * @param e
     */
    const handleTextInput = async (e) => {
        if (e.key === 'Enter') {
            await sendMessage()
        }
    }

    const renderForm = () => {
        return (
            <div className="ck-chat-input-container">
                <MKInput
                    type="text"
                    value={content}
                    className="ck-chat-input"
                    placeholder="Stuur een bericht..."
                    onChange={(e) => {
                        setContent(e.target.value)
                    }}
                    autocomplete="off"
                    onKeyPress={handleTextInput}
                    fullWidth
                />
                {!isPlatform('ios') && !isPlatform('android') && (
                    <CKEmojiPicker
                        onSelect={(e) => {
                            setContent((prev) => (prev += e))
                        }}
                    />
                )}
                <MKButton
                    variant="outlined"
                    className="ck-chat-btn"
                    color="info"
                    onClick={(e) => {
                        sendMessage()
                    }}
                    size="large"
                    iconOnly
                    circular
                >
                    <SendIcon />
                </MKButton>
            </div>
        )
    }

    return (
        <div className={clsx('ck-chat-box', { 'p-3': !isMobile })}>
            {userContext.user && (
                <div className="ck-chat-box-container">
                    <div className="ck-chat-center-container">
                        {chatHeader}
                        {renderConversation()}
                        {renderForm()}
                    </div>
                </div>
            )}
        </div>
    )
}
export default CKChatBox
