import { FormControl } from '@mui/material'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKSelect from 'components/Form/CKSelect'
import { CKLoading } from 'components/Generic/CKLoading'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import Address from 'models/Address'
import { FC, useCallback, useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import UserService from 'services/UserService'

interface Props {}

const LocationSettings: FC<Props> = () => {
    const {
        preferences,
        isLoading: isLoadingPreferences,
        isError: isErrorPreferences,
    } = UserService.usePreferences()

    const [address, setAddress] = useState<Address>(new Address())
    const [saving, setSaving] = useState<boolean>(false)
    const distances = [3, 5, 10, 15, 25, 50, 75]
    const [radius, setRadius] = useState(50)
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const fetchedAddress = await UserService.getMyAddress()
                setAddress(fetchedAddress)
                setIsError(false)
            } catch (error) {
                console.error(error)
                setIsError(true)
            }
            setIsLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (preferences) {
            setRadius(preferences.event_near_radius)
            setIsLoading(false)
            setIsError(false)
        }
        if (isLoadingPreferences) {
            setIsLoading(true)
        }
        if (isErrorPreferences) {
            setIsError(true)
        }
    }, [isErrorPreferences, isLoadingPreferences, preferences])

    const save = useCallback(async () => {
        setSaving(true)

        try {
            await UserService.updateMyAddress(address)
            await UserService.updatePreferences({ event_near_radius: radius })
        } catch (e) {
            console.error(e)
        }

        setSaving(false)
    }, [address, radius])

    const renderRadiusSelector = () => (
        <FormControl fullWidth className="mt-3">
            <MKTypography variant="caption" color="text">
                Straal*:
            </MKTypography>
            <CKSelect
                variant="standard"
                options={distances.map((a) => {
                    return { value: a, label: '< ' + a + 'km' }
                })}
                defaultValue={radius}
                onSelect={(e) => {
                    const value: number = typeof e === 'string' ? parseInt(e) : e
                    setRadius(value)
                }}
            />
        </FormControl>
    )

    if (isLoading) return <CKLoading />
    if (isError) return <div>Error loading data</div>

    return (
        <>
            <MKTypography variant="body2" component="div" color="text" mb={3}>
                We gebruiken jouw locatie enkel om je meldingen te sturen als er evenementen in jouw
                buurt zijn.
            </MKTypography>
            <CKAddressPicker
                label="Adres"
                address={address}
                disableFullWidth
                onSelect={(_address: Address) => setAddress(_address)}
            />
            {renderRadiusSelector()}
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </>
    )
}

export default LocationSettings
