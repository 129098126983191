import Address from 'models/Address'
import Event from 'models/Event'
import Location from 'models/Location'
import User from 'models/User'
import CKIndexProps from 'utils/CKIndexProps'
import AuthService, { addSearchParams, ckFetcher, useSWRPaginated } from './AuthService'
import Organisation from 'models/Organisation'
import { ARTISTS, ME, UPCOMING_EVENTS, USERS } from './Constants'
import Workshop from 'models/Workshop'
import useSWR, { mutate } from 'swr'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ImageService from './ImageService'
import { useUserContext, useUserContextDispatch } from 'contexts/UserContext'
import ComedyTag from 'models/ComedyTag'
import ComedyTagService from './ComedyTagService'

interface UpdateUserPreferencesParameters {
    email?: any
    event_near_radius?: number
}

export function useArtistEdit(slug: string) {
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<any>({})

    const { user: loggedInUser } = useUserContext();
    const userDispatch = useUserContextDispatch()

    const [selectedComedyTags, setSelectedComedyTags] = useState<ComedyTag[]>([])
    const [unselectedComedyTags, setUnselectedComedyTags] = useState<ComedyTag[]>([])

    const [pictureData, setPictureData] = useState<any>()
    const [bannerData, setBannerData] = useState<any>()
    const [saving, setSaving] = useState<boolean>(false)
    const history = useHistory()
    const { mutate } = useArtist(slug)

    useMemo(() => {
        if (slug) {
            UserService.get(slug).then((e) => {
                setUser(e)
                setLoading(false)

                const selectedTagsIds = e.$comedy_tags.map((tag) => tag.$id)

                ComedyTagService.getAll().then((tags) => {
                    const _unselectedTags = tags.filter(
                        (tag) => !selectedTagsIds.includes(tag.$id)
                    )
                    setSelectedComedyTags(e.$comedy_tags)
                    setUnselectedComedyTags(_unselectedTags)
                })
            });

        }
    }, [slug])



    const memoizedValue = useMemo(() => {
        const validate = () => {
            const _errors = {}

            if (!user.$first_name) {
                _errors['firstName'] = 'Voornaam is verplicht'
            }
            if (!user.$last_name) {
                _errors['lastName'] = 'Achternaam is verplicht'
            }
            if (!user.$birthdate) {
                _errors['birthdate'] = 'Geboortedatum is verplicht'
            }

            setErrors(_errors)
            return Object.keys(_errors).length === 0
        }

        const save = async () => {
            setSaving(true)
            try {
                const validated = validate()
                if (validated) {
                    if (pictureData) {
                        user.$picture = await ImageService.upload({
                            base64: pictureData.content,
                            extension: pictureData.ext,
                            name: user.$name + ' - PICTURE',
                            type: 'LOGO',
                        })
                    }

                    if (bannerData) {
                        user.$banner = await ImageService.upload({
                            base64: bannerData.content,
                            extension: bannerData.ext,
                            name: user.$name + ' - BANNER',
                            type: 'BANNER',
                        })
                    }
                    user.$comedy_tags = selectedComedyTags
                    const updatedUser = await UserService.update(user)
                    if (updatedUser.$id === loggedInUser?.$id) {
                        userDispatch({
                            type: 'REFRESH',
                            user: updatedUser,
                        })
                    }
                    mutate()
                    history.push(updatedUser.$detailUrl)
                }
            } catch (e) {
                console.error(e)
            }
            setSaving(false)
        }

        return {
            user,
            loading,
            setPictureData,
            setBannerData,
            save,
            saving,
            errors,
            selectedComedyTags,
            setSelectedComedyTags,
            unselectedComedyTags,
            setUnselectedComedyTags
        };

    }, [
        user,
        loading,
        setPictureData,
        setBannerData,
        saving,
        bannerData,
        pictureData,
        history,
        selectedComedyTags,
        setSelectedComedyTags,
        errors,
        mutate,
        userDispatch,
        loggedInUser?.$id,
        unselectedComedyTags,
        setUnselectedComedyTags
    ])

    return memoizedValue
}

export function useArtist(slug: string) {
    const { data, ...props } = useSWR(`${USERS}/${slug}`)

    let user = null
    if (data) {
        user = User.fromJSON(data)
    }

    return {
        user: user,
        ...props,
    }
}

export function useManagedOrganisations(search: string = '', page: number = 1, perPage: number = 10) {
    const { data, ...props } = useSWRPaginated(`${USERS}/${ME}/managed-organisations?page=${page}&per_page=${perPage}&search=${search}`)

    const _organisations = []
    if (data) {
        for (const o of data) {
            _organisations.push(Organisation.fromJSON(o))
        }
    }

    return {
        organisations: _organisations,
        default: _organisations?.length > 0 ? _organisations[0] : null,
        hasOrganisations: _organisations.length > 0,
        ...props,
    }
}

export function useManagedWorkshops(search: string = '', page: number = 1, perPage: number = 10) {
    const { data, ...props } = useSWRPaginated(`${USERS}/${ME}/managed-workshops?page=${page}&per_page=${perPage}&search=${search}`)

    const _workshops = []
    if (data) {
        for (const o of data) {
            _workshops.push(Workshop.fromJSON(o))
        }
    }

    return {
        workshops: _workshops,
        default: _workshops?.length > 0 ? _workshops[0] : null,
        hasWorkshops: _workshops.length > 0,
        ...props,
    }
}

export function useArtists(getProps: CKIndexProps) {
    const path = addSearchParams('artists', getProps)
    console.log(getProps)
    const { data, ...props } = useSWRPaginated(path)

    const _users = []
    if (data) {
        for (const u of data) {
            _users.push(User.fromJSON(u))
        }
    }

    return {
        artists: _users,
        default: _users?.length > 0 ? _users[0] : null,
        hasArtists: data && data.length > 0,
        ...props,
    }
}

const UserService = {
    get: async function (slug: string) {
        const resp = await AuthService.get(`${USERS}/${slug}`)

        const user = User.fromJSON(resp.data)
        return user
    },
    update: async function (user: User) {
        const resp = await AuthService.put(`${USERS}/${user.$slug}`, user)

        const updatedUser = User.fromJSON(resp.data)
        return updatedUser
    },
    post: async function (user: User) {
        const resp = await AuthService.post(USERS, user)

        const newUser = User.fromJSON(resp.data)
        return newUser
    },
    index: async function (search?: string, page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(USERS, {
            page: page,
            per_page: perPage,
            search: search,
        })

        const users = []

        for (const json of resp.data.data) {
            users.push(User.fromJSON(json))
        }
        resp.data.data = users
        return resp.data
    },
    upcomingEvents: async function (userSlug: string, page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(`${USERS}/${userSlug}/${UPCOMING_EVENTS}`, {
            page: page,
            per_page: perPage,
        })

        const events = []
        for (const json of resp.data.data) {
            events.push(Event.fromJSON(json))
        }
        resp.data.events = events
        return resp.data
    },
    pastEvents: async function (userSlug: string, page: number = 1, perPage: number = 10) {
        const resp = await AuthService.get(`${USERS}/${userSlug}/past-events`, {
            page: page,
            per_page: perPage,
        })

        const events = []
        for (const json of resp.data.data) {
            events.push(Event.fromJSON(json))
        }
        resp.data.events = events
        return resp.data
    },
    unreadMessages: async function () {
        const resp = await AuthService.get(`${USERS}/${ME}/unread-messages`)
        return resp.data
    },
    getCalendar: async function (month: number, year: number) {
        const resp = await AuthService.get(`${USERS}/${ME}/calendar`, {
            month: month,
            year: year,
        })

        const result: Event[] = []
        for (const json of resp.data) {
            result.push(Event.fromJSON(json))
        }

        return result
    },
    getManagedEvents: async function (page: number = 1, perPage: number = 10, search: string = '') {
        const resp = await AuthService.get(`${USERS}/${ME}/managed-events`, {
            per_page: perPage,
            page: page,
            search: search,
        })

        const result: Event[] = []

        for (const e of resp.data.data) {
            result.push(Event.fromJSON(e))
        }

        resp.data.data = result
        return resp.data
    },
    getManagedLocations: async function (
        page: number = 1,
        perPage: number = 10,
        search: string = ''
    ) {
        const resp = await AuthService.get(`${USERS}/${ME}/managed-locations`, {
            per_page: perPage,
            page: page,
            search: search,
        })

        const result: Location[] = []

        for (const e of resp.data.data) {
            result.push(Location.fromJSON(e))
        }

        resp.data.data = result
        return resp.data
    },
    getManagedOrganisations: async function (
        page: number = 1,
        perPage: number = 10,
        search: string = ''
    ) {
        const resp = await AuthService.get(`${USERS}/${ME}/managed-organisations`, {
            per_page: perPage,
            page: page,
            search: search,
        })

        const result: Organisation[] = []

        for (const e of resp.data.data) {
            result.push(Organisation.fromJSON(e))
        }

        resp.data.data = result
        return resp.data
    },
    usePreferences: () => {
        const { data, error } = useSWR(`${USERS}/${ME}/preferences`, ckFetcher, {
            revalidateOnFocus: false,
        })
        return {
            preferences: data,
            isLoading: !error && !data,
            isError: error,
        }
    },
    updatePreferences: async function (preferences: UpdateUserPreferencesParameters) {
        const resp = await AuthService.put(`${USERS}/${ME}/preferences`, preferences)
        // Update the SWR cache after updating preferences
        mutate(`${USERS}/${ME}/preferences`)
        return resp.data
    },
    getMyAddress: async function () {
        const data = (await AuthService.get(`${USERS}/${ME}/address`)).data

        if (!data || Object.keys(data).length === 0) {
            return undefined
        }

        return Address.fromJSON(data)
    },
    useAddress: () => {
        const { data, error } = useSWR(`${USERS}/${ME}/address`, ckFetcher, {
            revalidateOnFocus: false,
        })
        return {
            address: data ? Address.fromJSON(data) : undefined,
            isLoading: !error && !data,
            isError: error,
        }
    },
    updateMyAddress: async function (address: Address) {
        const resp = await AuthService.put(`${USERS}/${ME}/address`, { address: address })
        // Update the SWR cache after updating the address
        mutate(`${USERS}/${ME}/address`)
        return resp.data
    },
    changePassword: async function (oldPassword: string, password: string) {
        return await AuthService.put(`${USERS}/${ME}/change-password`, {
            oldPassword: oldPassword,
            password: password,
        })
    },
    changeEmail: async function (email: string) {
        return await AuthService.put(`${USERS}/${ME}/change-email`, {
            email: email,
        })
    },
    getArtists: async function (props: CKIndexProps) {
        const resp = await AuthService.get(ARTISTS, props)
        const users = []

        for (const json of resp.data.data) {
            users.push(User.fromJSON(json))
        }

        resp.data.data = users
        return resp.data
    },
    deleteMe: async function () {
        await AuthService.delete(`${USERS}/${ME}`)
        AuthService.removeTokens()
    },
}
export default UserService
