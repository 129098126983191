import { Container, TableBody, TableCell, TableRow } from '@mui/material'
import { CKLoading } from 'components/Generic/CKLoading'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ArrowBackIcon } from 'components/UI/CKIcons'
import CKTable from 'components/UI/CKTable/CKTable'
import CKTableHead from 'components/UI/CKTable/CKTableHead'
import Event from 'models/Event'
import { FC } from 'react'
import { useTicketSalesOverview } from 'services/TicketService'

interface Props {
    event: Event
    onBack: () => void
}

export const TicketSalesOverview: FC<Props> = ({ event, onBack }) => {
    const { sales, isLoading } = useTicketSalesOverview(event)

    if (isLoading) return <CKLoading />

    return (
        <Container>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <MKButton 
                    variant="outlined" 
                    color="info" 
                    onClick={onBack}
                    size="small"
                    sx={{ minWidth: 'auto' }}
                >
                    <ArrowBackIcon className="me-1" fontSize="small" />
                    Terug
                </MKButton>
                <MKTypography variant="h4" sx={{ textAlign: 'right', ml: 2 }}>
                    Verkochte Tickets
                </MKTypography>
            </div>

            <CKTable>
                <CKTableHead>
                        <TableCell>Email</TableCell>
                        {/* <TableCell align="right">€</TableCell> */}
                        <TableCell align="right">Gescand</TableCell>
                </CKTableHead>
                <TableBody>
                    {sales.map((sale) => (
                        <TableRow key={sale.ticket_type}>
                            <TableCell>{sale.user_email}</TableCell>
                            {/* <TableCell align="right">€{sale.ticket_price}</TableCell> */}
                            <TableCell align="right">{sale.scanned_tickets}/{sale.total_tickets}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </CKTable>
        </Container>
    )
}
