export default class Address {
    private lat: number | undefined
    private lng: number | undefined
    private street: string | undefined
    private region: string | undefined
    private postal_code: string | undefined
    private street_number: string | undefined
    private city: string | undefined
    private full_address: string | undefined

    constructor(
        lat: number | undefined = undefined,
        lng: number | undefined = undefined,
        street: string | undefined = undefined,
        region: string | undefined = undefined,
        postal_code: string | undefined = undefined,
        street_number: string | undefined = undefined,
        city: string | undefined = undefined,
        full_address: string | undefined = undefined
    ) {
        this.lat = lat
        this.lng = lng
        this.street = street
        this.region = region
        this.postal_code = postal_code
        this.street_number = street_number
        this.city = city
        this.full_address = full_address
    }

    public static fromJSON(json: any) {
        let result = new Address()
        result = Object.assign(result, json)
        return result
    }

    public get $fullAddress(): string | undefined {
        return this.full_address
    }
    public set $fullAddress(value: string | undefined) {
        this.full_address = value
    }

    public get $city(): string | undefined {
        return this.city
    }
    public set $city(value: string | undefined) {
        this.city = value
    }

    public get $street(): string | undefined {
        return this.street
    }
    public set $street(value: string | undefined) {
        this.street = value
    }

    public get $region(): string | undefined {
        return this.region
    }
    public set $region(value: string | undefined) {
        this.region = value
    }

    public get $postalCode(): string | undefined {
        return this.postal_code
    }
    public set $postalCode(value: string | undefined) {
        this.postal_code = value
    }

    public get $streetNumber(): string | undefined {
        return this.street_number
    }
    public set $streetNumber(value: string | undefined) {
        this.street_number = value
    }

    public get $lat(): number | undefined {
        return this.lat
    }
    public set $lat(value: number | undefined) {
        this.lat = value
    }

    public get $lng(): number | undefined {
        return this.lng
    }
    public set $lng(value: number | undefined) {
        this.lng = value
    }

    public get $name(): string | undefined {
        return 'EENS NAAR KIJKEN'
    }
}
