import { Card, Grid, useTheme } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useMqtt } from 'contexts/MqttContext'
import { useUserContext } from 'contexts/UserContext'
import ChatRoom from 'models/ChatRoom'
import { FC, useEffect, useState } from 'react'
import ChatService from 'services/ChatService'
import TimeUtils from 'utils/TimeUtils'

interface Props {
    room: ChatRoom
    onClick?: (e) => void
}

const CKChatRoomCard: FC<Props> = (props) => {
    const [room, setRoom] = useState<ChatRoom>(props.room)
    const lastMsg = room.$lastMessage
    const theme = useTheme()
    const userContext = useUserContext()
    const mqtt = useMqtt()

    let listenerId = ''

    useEffect(() => {
        if (mqtt) {
            listenerId = mqtt.subscribeTo(`chat/${props.room.$slug}`, async (msg) => {
                const _room = await ChatService.getChatRoom(props.room.$slug)
                setRoom(_room)
            })
        }
    }, [])

    return (
        <Card
            style={{
                backgroundColor:
                    theme['palette'].info.main + (room.$amountUnread !== 0 ? '10' : '03'),
                height: '75px',
            }}
            onClick={(e) => {
                room.$amountUnread = 0
                props.onClick(e)
            }}
            className={
                'w-100 p-2 my-2 d-flex align-items-center justify-content-center ck-chat-room-card'
            }
        >
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={3}
                    md={2}
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '55px' }}
                >
                    {room.$icon && (
                        <MKBox
                            style={{
                                objectFit: 'contain',
                                borderRadius: '100%',
                            }}
                            component="img"
                            my="2"
                            maxHeight="100%"
                            maxWidth={'100%'}
                            src={room.$icon.$url}
                            alt={room.$name}
                            borderRadius="lg"
                            circular="true"
                            // shadow="lg"
                        />
                    )}
                </Grid>
                <Grid item xs={6} md={8} className="d-flex flex-column justify-content-center">
                    <MKTypography
                        variant="h5"
                        mb={1}
                        className={'chat-room-title'}
                        style={{
                            fontWeight: room.$amountUnread === 0 ? 'normal' : 'bold',
                        }}
                    >
                        {room.$name}
                    </MKTypography>
                    <MKTypography
                        variant="button"
                        fontWeight={room.$amountUnread !== 0 && 'bold'}
                        className="chat-room-label text-start"
                    >
                        {lastMsg ? (
                            <>
                                {lastMsg.$user &&
                                userContext.user &&
                                lastMsg.$user.$id === userContext.user.$id
                                    ? 'Jij'
                                    : lastMsg.$user.$first_name}
                                : {lastMsg.$content}
                            </>
                        ) : (
                            'Geen berichten'
                        )}
                    </MKTypography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    md={2}
                    className="d-flex justify-content-center align-items-center flex-column"
                >
                    <div className="unread-badge-container">
                        {room.$amountUnread > 0 && (
                            <MKBox bgColor={'info'} className="unread-msg-badge mb-2">
                                {room.$amountUnread}
                            </MKBox>
                        )}
                    </div>
                    <MKTypography
                        variant="button"
                        fontWeight={room.$amountUnread !== 0 && 'bold'}
                        className="last-msg-time text-center"
                    >
                        {lastMsg && TimeUtils.toExtendedTimeString(lastMsg.$created_at)}
                    </MKTypography>
                </Grid>
            </Grid>
        </Card>
    )
}
export default CKChatRoomCard
