import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

interface NotificationTimeProps {
    date: Date
}

export const NotificationTime: FC<NotificationTimeProps> = ({ date }) => {
    const getTimeDifference = (date: Date) => {
        const now = new Date()
        const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000)

        const minutes = Math.floor(diffInSeconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)
        const weeks = Math.floor(days / 7)
        const months = Math.floor(days / 30)

        if (diffInSeconds < 60) return 'nieuw'
        if (months > 0) return `${months} mnd`
        if (weeks > 0) return `${weeks} w`
        if (days > 0) return `${days} d`
        if (hours > 0) return `${hours} u`
        return `${minutes} m`
    }

    return (
        <MKTypography variant="body2" color="textSecondary">
            {getTimeDifference(date)}
        </MKTypography>
    )
} 