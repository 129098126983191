import { NotificationType } from "enums/NotificationType"

interface NotificationProps {
    id?: string
    type?: NotificationType
    title?: string
    message?: string
    read?: boolean
    created_at?: Date
}

export default class Notification {
    private id: string
    private type: NotificationType
    private title: string
    private message: string
    private read: boolean
    private created_at: Date

    constructor({
        id,
        type,
        title,
        message,
        read,
        created_at,
    }: NotificationProps = {}) {
        this.id = id
        this.type = type
        this.title = title
        this.message = message
        this.read = read
        this.created_at = created_at
    }

    public static fromJSON(json: {
        id: string
        type: string
        data: { title: string; message: string }
        read_at: string | null
        created_at: string
    }): Notification {
        return new Notification({
            id: json.id,
            type: json.type as NotificationType,
            title: json.data.title,
            message: json.data.message,
            read: !!json.read_at,
            created_at: new Date(json.created_at),
        })
    }

    public get $id(): string {
        return this.id
    }
    public get $type(): NotificationType {
        return this.type
    }
    public get $title(): string {
        return this.title
    }
    public get $message(): string {
        return this.message
    }
    public get $read(): boolean {
        return this.read
    }
    public get $createdAt(): Date {
        return this.created_at
    }

    public get $url(): string {
        console.error('Notification called instead of child notification')
        return ''
    }

    public toggleRead() {
        this.read = !this.read
    }
}