import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { useUserContext, useUserContextDispatch } from 'contexts/UserContext'
import User from 'models/User'
import { FC, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Zoom, toast } from 'react-toastify'
import UserService from 'services/UserService'

interface Props {}

const EmailChange: FC<Props> = () => {
    const userContext = useUserContext()
    const userDispatch = useUserContextDispatch()

    const [email, setEmail] = useState<string>('')
    const [confirmEmail, setConfirmEmail] = useState<string>('')
    const [errors, setErrors] = useState({})
    const [saving, setSaving] = useState<boolean>(false)

    const save = async () => {
        setSaving(true)

        if (!valid()) {
            setSaving(false)
            return
        }

        try {
            await UserService.changeEmail(email)
            const user: User = userContext.user
            user.$email = email
            userDispatch({
                type: 'REFRESH',
                user: user,
            })

            setEmail('')
            setConfirmEmail('')
            setErrors({})

            toast.success('E-mailadres aangepast', {
                containerId: 'B',
                transition: Zoom,
            })
        } catch (e) {
            if (e.response.status === 422) {
                const _errors = {}
                _errors['email'] = 'E-mailadres al in gebruik'
                setErrors(_errors)
            } else {
                console.error(e)
            }
        }
        setSaving(false)
    }

    const valid = () => {
        const _errors = {}

        if (!email) {
            _errors['email'] = 'Geef een e-mailadres in'
        } else if (email !== confirmEmail) {
            _errors['confirmEmail'] = 'E-mailadressen komen niet overeen'
        }

        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }

    /**
     * Event handler on chat input
     * @param e
     */
    const handleTextInput = async (e) => {
        if (e.key === 'Enter') {
            save()
        }
    }

    return (
        <>
            <MKBox mb={2}>
                <MKInput
                    type="email"
                    label="Huidig e-mailadres"
                    defaultValue={userContext?.user?.$email}
                    fullWidth
                    disabled
                />
            </MKBox>
            <MKBox mb={2}>
                <MKInput
                    type="email"
                    label="Nieuw e-mailadres"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    onKeyPress={handleTextInput}
                    error={errors.hasOwnProperty('email')}
                />
                <CKFormFeedback>{errors['email']}</CKFormFeedback>
            </MKBox>
            <MKBox mb={2}>
                <MKInput
                    type="email"
                    label="Bevestig nieuw e-mailadres"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    fullWidth
                    onKeyPress={handleTextInput}
                    error={errors.hasOwnProperty('confirmEmail')}
                />
                <CKFormFeedback>{errors['confirmEmail']}</CKFormFeedback>
            </MKBox>
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </>
    )
}
export default EmailChange
