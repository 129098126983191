import DomainAddIcon from '@mui/icons-material/DomainAdd'
import { Container, Grid } from '@mui/material'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKImageUpload from 'components/Form/CKImageUpload'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ImageIcon, InfoIcon, SaveIcon, ShareIcon } from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import Address from 'models/Address'
import Location from 'models/Location'
import { FC, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import ImageService from 'services/ImageService'
import LocationService from 'services/LocationService'
import { LocationPageContainer } from '../LocationPageContainer'

interface Props { }
interface Props { }

const PageLocationEdit: FC<Props> = () => {
    const locationId = useParams()['locationId']
    const [location, setLocation] = useState<Location | undefined>()

    const [logoData, setLogoData] = useState<any>()
    const [bannerData, setBannerData] = useState<any>()
    const [errors, setErrors] = useState({})

    // const [logo, setLogo] = useState

    const [saving, setSaving] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const history = useHistory()

    useMemo(async () => {
        setLoading(true)
        if (locationId === 'new') {
            setLocation(new Location())
        } else {
            const _location = await LocationService.get(locationId)
            if (!_location.hasRight('FULL')) history.push(_location.$detailUrl)
            setLocation(_location)
        }
        setLoading(false)
    }, [locationId, history])

    const validate = () => {
        const _errors = {}
        const defaultMsg = 'Verplicht veld!'

        _errors['name'] = !location.$name ? defaultMsg : false
        _errors['location'] =
            !location.$address || !location.$address.$lat || !location.$address.$lng
                ? defaultMsg
                : false

        setErrors(_errors)

        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        setSaving(true)
        try {
            const validated = validate()
            if (validated) {
                if (logoData) {
                    location.$logo = await ImageService.upload({
                        base64: logoData.content,
                        extension: logoData.ext,
                        name: location.$name + ' - LOGO',
                        type: 'LOGO',
                    })
                }

                if (bannerData) {
                    location.$banner = await ImageService.upload({
                        base64: bannerData.content,
                        extension: bannerData.ext,
                        name: location.$name + ' - BANNER',
                        type: 'BANNER',
                    })
                }

                if (locationId === 'new') {
                    const loc = await LocationService.post(location)
                    history.push(loc.$detailUrl)
                } else {
                    const updatedLocation = await LocationService.update(location)
                    history.push(updatedLocation.$detailUrl)
                    // setLocation(loc);
                }
            }
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    const renderGeneral = () => {
        if (!location) return null
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MKInput
                        label="Naam"
                        title="Naam"
                        error={errors['name'] ? true : false}
                        defaultValue={location.$name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            location.$name = e.target.value
                        }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{errors['name']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKInput
                        type="standard"
                        multiline
                        defaultValue={location.$description}
                        placeholder="Geef een beschrijving van de locatie zodat mensen je kunnen vinden..."
                        title="Beschrijving"
                        label="Beschrijving"
                        onChange={(e) => {
                            location.$description = e.target.value
                        }}
                        InputLabelProps={{ shrink: true }}
                        rows={8}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <CKAddressPicker
                        label="Adres"
                        address={location.$address}
                        error={errors['location']}
                        disableFullWidth
                        onSelect={(address: Address) => {
                            location.$address = address
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderMedia = () => {
        if (!location) return
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <MKTypography variant="caption">Logo</MKTypography>
                    <CKImageUpload
                        defaultImage={location.$logo}
                        onChange={(
                            files: Array<{
                                fileName: string
                                ext: string
                                content: string | ArrayBuffer
                            }>
                        ) => {
                            setLogoData(files[0])
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <MKTypography variant="caption">Banner</MKTypography>

                    <CKImageUpload
                        defaultImage={location.$banner}
                        onChange={(
                            files: Array<{
                                fileName: string
                                ext: string
                                content: string | ArrayBuffer
                            }>
                        ) => {
                            setBannerData(files[0])
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    const sidebarSteps = {
        root: {
            general: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: renderGeneral(),
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: renderMedia(),
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={location} />,
            },
        },
    }

    return (
        <LocationPageContainer location={location} loading={loading}>
            <CKPageContent>
                {location?.$logo?.$url ? (
                    <CKPageBadge src={location?.$logo.$url} />
                ) : (
                    <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                        <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                    </CKPageBadge>
                )}
                <CKPageTitle>
                    {locationId === 'new' ? 'Creëer een locatie' : 'Pas locatie aan'}
                </CKPageTitle>
                <MKTypography variant="caption" className="text-center">
                    Hoppa, nog een plaats bij om te lachen. Vul de gegevens van jouw
                    locatie hier in en zorg ervoor dat je meute aan comedians je optijd
                    kunnen vinden!
                </MKTypography>
                <Container className="my-3">
                    <CKSidebarTabs
                        sections={sidebarSteps}
                        footer={
                            <div className="text-center">
                                <MKButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    onClick={save}
                                    disabled={saving}
                                >
                                    {saving ? (
                                        <p className="mb-0 me-2">
                                            <ClipLoader size={15} color={'white'} />
                                        </p>
                                    ) : (
                                        <SaveIcon className="me-2" />
                                    )}
                                    {locationId === 'new'
                                        ? 'Creëer locatie!'
                                        : 'Sla op!'}
                                </MKButton>
                            </div>
                        }
                        hideGroup
                    />
                </Container>
            </CKPageContent>
        </LocationPageContainer>

    )
}
export default PageLocationEdit
