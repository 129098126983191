import { FC } from 'react'
import MKTypography from 'components/MaterialKit/MKTypography'
import EventNotification from 'models/Notifications/EventNotification'
import { NotificationTypeProps, NotificationTypeComponent } from './types'
import EventNotificationLogo from '../Logos/EventNotificationLogo'

const Text: FC<NotificationTypeProps> = ({ notification }) => {
    const eventNotification = notification as EventNotification
    const firstTwoWords = eventNotification.$title.split(' ').slice(0, 2).join(' ')
    
    return (
        <MKTypography variant="body2" color="text" display="inline">
            {firstTwoWords}{' '} de line-up van{' '}
            <b>{eventNotification.$event.$name}</b>
        </MKTypography>
    )
}

const Logo: FC<NotificationTypeProps> = ({ notification }) => {
    const eventNotification = notification as EventNotification
    return <EventNotificationLogo notification={eventNotification} />
}

export const Lineup: NotificationTypeComponent = {
    Text,
    Logo,
} 