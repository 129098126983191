import { NotificationType } from 'enums/NotificationType'
import { Default } from './Default'
import { EventChanges } from './EventChanges'
import { EventNearby } from './EventNearby'
import { EventReminder } from './EventReminder'
import { Lineup } from './Lineup'
import { NotificationRegistry } from './types'

export const notificationRegistry: NotificationRegistry = {
    [NotificationType.EVENT_CHANGES]: EventChanges,
    [NotificationType.EVENT_REMINDER]: EventReminder,
    [NotificationType.EVENT_NEARBY]: EventNearby,
    [NotificationType.LINEUP]: Lineup,
    default: Default,
}
