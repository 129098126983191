import PlaceIcon from '@mui/icons-material/Place'
import TuneIcon from '@mui/icons-material/Tune'
import { Collapse, FormControl, Grid, InputAdornment } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKCheckBox from 'components/Form/CKCheckBox'
import CKSearchBar from 'components/Form/CKSearchBar'
import CKSelect from 'components/Form/CKSelect'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKPagination from 'components/UI/CKPagination'
import { useCKSearchParams, useCKSetSearchParams } from 'contexts/SearchParamsContext'
import { FC, useState } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { useEvents } from 'services/EventService'
import { useIsMobile } from 'utils/ViewportUtils'
import { CKEventRow } from '../CKEventRow'
import Address from 'models/Address'

interface Props {}

const CKEventOverview: FC<Props> = (props) => {
    // const [events, setEvents] = useState([])
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
    // const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const {
        location: locationSearchParam,
        address: addressSearchParam,
        lineup: lineupSearchParam,
    } = useCKSearchParams()
    const [locationSearch, setLocationSearch] = useState<{
        lat?: number
        lng?: number
        radius?: number
    }>({
        radius: 50,
        lat: parseFloat(locationSearchParam?.split(',')[0]),
        lng: parseFloat(locationSearchParam?.split(',')[1]),
    })
    const [showFilters, setShowFilters] = useState(false)
    const isMobile = useIsMobile()

    const perPage = 6

    const [page, setPage] = useState(1)
    const setSearchParams = useCKSetSearchParams()

    const [lineupFilters, setLineupFilters] = useState({
        lineupComplete: lineupSearchParam === 'complete',
        spotsAvailable: lineupSearchParam === 'spots_available',
    })

    const { events, paginationInfo, isLoading } = useEvents(
        page,
        perPage,
        searchTerm,
        locationSearch,
        lineupFilters
    )

    const renderFilters = () => {
        const distances = [3, 5, 10, 15, 25, 50, 75]

        const forgeLineupFilterText = (filters: {
            lineupComplete: boolean
            spotsAvailable: boolean
        }) => {
            let lineupFilterValue = 'all'
            if (filters.spotsAvailable && !filters.lineupComplete) {
                lineupFilterValue = 'spots_available'
            } else if (!filters.spotsAvailable && filters.lineupComplete) {
                lineupFilterValue = 'complete'
            }
            return lineupFilterValue
        }

        return (
            <div className="pb-5 px-3">
                <Grid container columnSpacing={3} rowSpacing={2}>
                    <Grid item xs={12}>
                        <MKTypography color="info" variant="button" fontWeight="bold">
                            Zoeken:
                        </MKTypography>
                        <CKSearchBar
                            onSearch={(e) => {
                                setSearchTerm(e)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MKTypography color="info" variant="button" fontWeight="bold">
                            Locatie:
                        </MKTypography>

                        <Grid container spacing={3} className="d-flex justify-content-center">
                            <Grid item xs={8}>
                                <CKAddressPicker
                                    defaultValue={addressSearchParam}
                                    className={'w-100'}
                                    onSelect={async ( address: Address ) => {
                                        if (!address.$lat || !address.$lng) return
                                        const loc = {
                                            lat: address.$lat,
                                            lng: address.$lng,
                                            radius: locationSearch.radius,
                                        }
                                        setSearchParams({
                                            location: `${address.$lat},${address.$lng},${locationSearch.radius}`,
                                            address: address.$fullAddress,
                                        })
                                        setLocationSearch(loc)
                                        // init(1, searchTerm, loc)
                                    }}
                                    placeholder="Locatie"
                                    hideMap={true}
                                    types={['locality']}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PlaceIcon fontSize="medium" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <CKSelect
                                        // variant="standard"
                                        options={distances.map((a) => {
                                            return { value: a, label: '< ' + a + 'km' }
                                        })}
                                        defaultValue={locationSearch.radius}
                                        onSelect={(e) => {
                                            const value: number =
                                                typeof e === 'string' ? parseInt(e) : e
                                            const loc = {
                                                lat: locationSearch.lat,
                                                lng: locationSearch.lng,
                                                radius: value,
                                            }
                                            setSearchParams({
                                                location: `${locationSearch.lat},${locationSearch.lng},${value}`,
                                            })
                                            setLocationSearch(loc)

                                            // init(1, searchTerm, loc)
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MKTypography color="info" variant="button" fontWeight="bold">
                            Line-up:
                        </MKTypography>
                        <Grid container className="px-2">
                            <Grid item xs={12}>
                                <CKCheckBox
                                    checked={lineupFilters.lineupComplete}
                                    onChange={(e) => {
                                        setLineupFilters({
                                            ...lineupFilters,
                                            lineupComplete: e.target.checked,
                                        })
                                        setSearchParams({
                                            lineup: forgeLineupFilterText({
                                                lineupComplete: e.target.checked,
                                                spotsAvailable: lineupFilters.spotsAvailable,
                                            }),
                                        })
                                    }}
                                    name="lineup complete"
                                    label="Line-up bekend"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CKCheckBox
                                    checked={lineupFilters.spotsAvailable}
                                    onChange={(e) => {
                                        setLineupFilters({
                                            ...lineupFilters,
                                            spotsAvailable: e.target.checked,
                                        })
                                        setSearchParams({
                                            lineup: forgeLineupFilterText({
                                                lineupComplete: lineupFilters.lineupComplete,
                                                spotsAvailable: e.target.checked,
                                            }),
                                        })
                                    }}
                                    name="lineup complete"
                                    label="Spots beschikbaar"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <MKTypography variant="h3">Evenementen</MKTypography>
                <MKButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={(e) => {
                        setShowFilters((prev) => !prev)
                    }}
                >
                    <TuneIcon className={clsx({ 'me-2': !isMobile })} /> {!isMobile && 'Filters'}
                </MKButton>
            </div>
            <Collapse in={showFilters} timeout="auto">
                {renderFilters()}
            </Collapse>

            <Grid container className="mt-1 justify-content-center" style={{ flex: '1 1 auto' }}>
                {isLoading ? (
                    <div className="w-100 d-flex justify-content-center align-items-center flex-column py-5 h-100">
                        <BeatLoader loading={true} color={'#1A73E8'} />
                        <MKTypography fontWeight="bold" className="mt-2">
                            Laden...
                        </MKTypography>
                    </div>
                ) : (
                    <>
                        {!events ||
                            (events.length === 0 && (
                                <div className="w-100 d-flex justify-content-center align-items-center flex-column py-5 h-100">
                                    <MKTypography fontWeight="bold" className="mt-2">
                                        Geen evenementen gevonden...
                                    </MKTypography>
                                </div>
                            ))}
                        {events && (
                            <>
                                {events.map((event, index) => {
                                    return (
                                        // <Grid
                                        //     item
                                        //     xs={12}
                                        //     lg={3}
                                        //     key={'event-' + event.$id}
                                        //     className="pt-1 px-2 pt-lg-2"
                                        // >
                                        //     <CKEventCard event={event} compact={isLarge} />
                                        // </Grid>
                                        <CKEventRow event={event} key={`event-${event.$id}`} />
                                    )
                                })}
                                <CKPagination info={paginationInfo} onSelect={setPage} />
                            </>
                        )}
                    </>
                )}
            </Grid>
        </>
    )
}
export default CKEventOverview
