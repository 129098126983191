import { FC } from 'react'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CircleNotificationsIcon } from 'components/UI/CKIcons'
import { NotificationTypeProps, NotificationTypeComponent } from './types'

const Text: FC<NotificationTypeProps> = ({ notification }) => {
    return (
        <MKTypography variant="body2" color="text" display="inline">
            {notification.$message}
        </MKTypography>
    )
}

const Logo: FC<NotificationTypeProps> = () => {
    return <CircleNotificationsIcon />
}

export const Default: NotificationTypeComponent = {
    Text,
    Logo,
} 