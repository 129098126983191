import { faClock as farClock } from '@fortawesome/free-regular-svg-icons'
import {
    faChevronDown,
    faChevronUp,
    faLink,
    faLinkSlash,
    faLocationDot,
    faMicrophoneLines,
    faTicket,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddIcon from '@mui/icons-material/Add'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ClearIcon from '@mui/icons-material/Clear'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DeleteIcon from '@mui/icons-material/Delete'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import EditIcon from '@mui/icons-material/Edit'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
// import ExpandLessIcon from '@mui/icons-material/ExpandLess'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupsIcon from '@mui/icons-material/Groups'
import LoupeIcon from '@mui/icons-material/Loupe'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MicIcon from '@mui/icons-material/Mic'
import BadgeIcon from '@mui/icons-material/Badge'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ConstructionIcon from '@mui/icons-material/Construction'
import ErrorIcon from '@mui/icons-material/Error'
import EuroIcon from '@mui/icons-material/Euro'
import ImageIcon from '@mui/icons-material/Image'
import InfoIcon from '@mui/icons-material/Info'
// import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RemoveIcon from '@mui/icons-material/Remove'
import SaveIcon from '@mui/icons-material/Save'
import ShareIcon from '@mui/icons-material/Share'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

export const MyTicketIcon = (props) => <FontAwesomeIcon icon={faTicket} {...props} />
const ExpandLessIcon = (props) => <FontAwesomeIcon icon={faChevronUp} {...props} />
const ExpandMoreIcon = (props) => <FontAwesomeIcon icon={faChevronDown} {...props} />
const MicIcon = (props) => <FontAwesomeIcon icon={faMicrophoneLines} {...props} />
const ConnectIcon = (props) => <FontAwesomeIcon icon={faLink} {...props} />
const DisconnectIcon = (props) => <FontAwesomeIcon icon={faLinkSlash} {...props} />
// @ts-ignore
const AccessTimeIcon = (props) => <FontAwesomeIcon icon={farClock} {...props} />
const TicketIcon = (props) => <FontAwesomeIcon icon={faTicket} {...props} />
const CheckIcon = ({ className, ...props }) => (
    <CheckCircleIcon className={'mb-1 ' + className} {...props} />
)
const LocationIcon = (props) => <FontAwesomeIcon icon={faLocationDot} {...props} />

export {
    AccessTimeIcon,
    BadgeIcon,
    BookmarkAddIcon,
    CalendarMonthIcon,
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CircleNotificationsIcon,
    ClearIcon,
    ConnectIcon,
    DashboardIcon,
    DeleteIcon,
    DisconnectIcon,
    DomainAddIcon,
    DraftsOutlinedIcon as MarkReadIcon,
    EditIcon,
    ErrorIcon,
    EditCalendarIcon as EventIcon,
    ExpandLessIcon,
    ExpandMoreIcon,
    ImageIcon,
    InfoIcon,
    LocationIcon,
    LoupeIcon,
    ManageAccountsIcon,
    MicIcon,
    MarkunreadOutlinedIcon as MarkUnreadIcon,
    // NotificationsIcon,
    RemoveIcon as MinusIcon,
    EuroIcon as MoneyIcon,
    GroupsIcon as OrganisationIcon,
    PictureAsPdfIcon as PDFIcon,
    AddIcon as PlusIcon,
    BeenhereIcon as PresenceIcon,
    QrCodeScannerIcon,
    RadioButtonCheckedIcon,
    RadioButtonUncheckedIcon,
    SaveIcon,
    ShareIcon,
    ShoppingCartIcon,
    TicketIcon,
    PersonIcon as UserIcon,
    ConstructionIcon as WorkshopIcon,
    ConstructionIcon as CustomLineupIcon
}
