import PostAddIcon from '@mui/icons-material/PostAdd'
import { Container, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CKSearchBar from 'components/Form/CKSearchBar'
import { CKLoading } from 'components/Generic/CKLoading'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKEventRow } from 'components/UI/CKEventRow'
import CKPagination from 'components/UI/CKPagination'
import { useUserContext } from 'contexts/UserContext'
import { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UserService from 'services/UserService'

interface Props { }
const CKEventManagement: FC<Props> = (props) => {
    const [events, setEvents] = useState([])
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [hasEvents, setHasEvents] = useState(false)
    const history = useHistory()
    const userContext = useUserContext()

    const perPage = useMemo(() => {
        if (isLarge) {
            return 8
        }
        return 5
    }, [isLarge])

    const [paginationInfo, setPaginationInfo] = useState<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
    }>()

    const DashboardTitle = ({ children }) => {
        return (
            <MKTypography
                variant="h3"
                color="info"
                mt={3}
                className="text-center ck-dashboard-title"
                textGradient
            >
                {children}
            </MKTypography>
        )
    }

    const init = async (page: number = 1, search = searchTerm) => {
        // setLoading(true);

        try {
            const _events = await UserService.getManagedEvents(page, perPage, search)
            if (_events.data.length > 0) {
                console.log()
                setHasEvents(true)
            }

            setEvents(_events.data)

            delete _events.data
            setPaginationInfo(_events)
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            {(hasEvents || (userContext.user && userContext.user.$isOrganizer)) && (
                <Container className="d-flex flex-column p-0" style={{ flex: '1 1 auto' }}>
                    <Grid
                        container
                        className="mt-3 justify-content-center"
                        style={{ flex: '1 1 auto' }}
                    >
                        {loading ? (
                            <CKLoading />
                        ) : (
                            <>
                                <Grid item xs={12} className="text-center">
                                    <MKButton
                                        color="info"
                                        variant="outlined"
                                        onClick={(e) => {
                                            if (e.ctrlKey) {
                                                window.open('admin/event/new', '_blank')
                                            } else {
                                                history.push('admin/event/new')
                                            }
                                        }}
                                    >
                                        <PostAddIcon className="me-2" /> Evenement aanmaken
                                    </MKButton>
                                    {hasEvents && (
                                        <CKSearchBar
                                            className="my-4"
                                            onSearch={(e) => {
                                                setSearchTerm(e)
                                                init(1, e)
                                            }}
                                        />
                                    )}
                                </Grid>
                                {!events ||
                                    (events.length === 0 && (
                                        <div className="w-100 d-flex justify-content-center align-items-center flex-column my-5 py-4 h-100">
                                            <MKTypography fontWeight="bold">
                                                Geen evenementen gevonden...
                                            </MKTypography>
                                        </div>
                                    ))}
                                {events && (
                                    <>
                                        {events.map((event, index) => {
                                            return (
                                                <CKEventRow event={event} useAdminUrl compact/>
                                            )
                                        })}
                                    </>
                                )
                                }

                                <CKPagination
                                    info={paginationInfo}
                                    onSelect={(page) => init(page)}
                                />
                            </>
                        )}
                    </Grid>
                </Container>
            )}
        </>
    )
}
export default CKEventManagement
