import { isPlatform } from '@ionic/react'
import HandshakeIcon from '@mui/icons-material/Handshake'
import { Container, Grid } from '@mui/material'
import logoComedyBooking from 'assets/images/logos/comedybooking-logo.png'
import logoDark from 'assets/images/logos/logo_dark.png'
import logoLight from 'assets/images/logos/logo_light.png'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useDarkMode } from 'contexts/DarkModeContext'
import { FC } from 'react'

interface Props { }
const CKFooter: FC<Props> = (props) => {
    const brand = {
        name: 'ComedyKit',
        image: logoLight,
        image_dark: logoDark,
        route: '/',
    }
    const darkMode = useDarkMode()
    const date = new Date().getFullYear()

    const copyright = (
        <Grid item xs={12} sx={{ textAlign: 'center' }} className="mt-1 pb-4">
            <MKTypography color="dark" variant="button" fontWeight="regular">
                All rights reserved. Copyright &copy; {date} Comedy Kit by{' '}
                <MKTypography
                    component="a"
                    href="https://www.bramkelchtermans.be"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="dark"
                    fontWeight="bold"
                >
                    Bram Kelchtermans
                </MKTypography>{' '}
                &{' '}
                <MKTypography
                    component="a"
                    href="https://www.linkedin.com/in/daan-schouteden/"
                    target="_blank"
                    rel="noreferrer"
                    variant="button"
                    color="dark"
                    fontWeight="bold"
                >
                    Daan Schouteden
                </MKTypography>
            </MKTypography>
        </Grid>
    )

    const Column1 = () => {
        return (
            <Grid item xs={3} className="d-flex flex-column align-items-start">
                <div className="d-flex flex-column align-items-center">
                    <MKBox
                        component="img"
                        src={darkMode.dark ? brand.image : brand.image_dark}
                        alt={brand.name}
                        maxWidth="150px"
                    />
                    <MKTypography variant="button" component="div">
                        <HandshakeIcon color="info" fontSize="medium" />
                    </MKTypography>
                    <MKBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <a href={'https://www.comedybooking.be'} target="_blank">
                            <MKBox
                                component="img"
                                src={logoComedyBooking}
                                alt={brand.name}
                                width="100%"
                                maxWidth={150}
                            />
                        </a>
                    </MKBox>
                </div>
            </Grid>
        )
    }

    const Links = () => {
        return (
            <>
                <Grid item xs={3} className="text-center">
                    <MKTypography
                        component="a"
                        variant="h6"
                        color="info"
                        href="/terms-and-conditions"
                    >
                        Gebruikersvoorwaarden
                    </MKTypography>
                </Grid>
                <Grid item xs={3} className="text-center">
                    <MKTypography component="a" variant="h6" color="info" href="/privacy">
                        Privacybeleid
                    </MKTypography>
                </Grid>
                <Grid item xs={3} className="text-center">
                    <MKTypography component="a" variant="h6" color="info" href="/feature-request">
                        Vraag een functie aan / meld een fout
                    </MKTypography>
                </Grid>
            </>
        )
    }

    const Column2 = () => {
        return (
            <Grid item xs={3} className="d-flex flex-column align-items-start" gap={1}>
                <MKTypography variant="h6" color="dark">
                    ComedyKit
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/"
                >
                    Home
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/artists"
                >
                    Artiesten
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/locations"
                >
                    Locaties
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/workshops"
                >
                    Workshops
                </MKTypography>
            </Grid>
        )
    }

    const Column4 = () => {
        return (
            <Grid item xs={3} className="d-flex flex-column align-items-start" gap={1}>
                <MKTypography variant="h6" color="dark">
                    Over
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/terms-and-conditions"
                >
                    Gebruikersvoorwaarden
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/privacy"
                >
                    Privacybeleid
                </MKTypography>
                <MKTypography
                    component="a"
                    variant="button"
                    color="info"
                    href="/feature-request"
                >
                    Vraag een functie aan / meld een fout
                </MKTypography>
            </Grid>
        )
    }


    return (
        <>
            {!isPlatform('android') && !isPlatform('ios') ? (
                <MKBox
                    pt={15}
                    px={1}
                    style={{
                        backgroundColor: 'rgb(240, 242, 245)',
                        color: 'white',
                    }}
                >
                    <MKBox component="footer">
                        <Container>
                            <Grid container spacing={3} className="d-flex ">
                                <Column1 />
                                <Column2 />
                                <Column4 />
                                <Grid item xs={3} />
                            </Grid>
                            {/* <Grid container spacing={3} className="d-flex justify-content-center">
                                <Logos />
                            </Grid> */}
                            <Grid
                                container
                                spacing={3}
                                className="d-flex justify-content-center mt-3 mb-4"
                            >
                                {/* <Links /> */}
                                {copyright}
                            </Grid>
                        </Container>
                    </MKBox>
                </MKBox>
            ) : (
                <MKBox
                    pt={10}
                    px={1}
                    style={{
                        backgroundColor: 'rgb(240, 242, 245)',
                        color: 'white',
                    }}
                ></MKBox>
            )}
        </>
    )
}
export default CKFooter
