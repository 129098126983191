import {
    Badge,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from '@mui/material'
import Notification from 'models/Notifications/Notification'
import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DeleteIcon, MarkReadIcon, MarkUnreadIcon } from '../CKIcons'
import { useNotificationsContext } from 'contexts/NotificationsContext'
import { NotificationLogo, NotificationText } from './NotificationComponents'
import { NotificationTime } from './NotificationTime'

interface ICKNotification {
    notification: Notification
}

const CKNotification: FC<ICKNotification> = ({ notification }) => {
    const { markAsRead, markAsUnread, deleteNotification } = useNotificationsContext()
    const history = useHistory()
    const [isActionInProgress, setIsActionInProgress] = useState(false)

    const handleMarkToggle = useCallback(async (e: React.MouseEvent) => {
        e.stopPropagation()
        if (isActionInProgress) return

        setIsActionInProgress(true)
        try {
            if (notification.$read) {
                await markAsUnread(notification)
            } else {
                await markAsRead(notification)
            }
        } finally {
            setIsActionInProgress(false)
        }
    }, [notification, markAsRead, markAsUnread, isActionInProgress])

    const handleDelete = useCallback(async (e: React.MouseEvent) => {
        e.stopPropagation()
        if (isActionInProgress) return

        setIsActionInProgress(true)
        try {
            await deleteNotification(notification)
        } finally {
            setIsActionInProgress(false)
        }
    }, [notification, deleteNotification, isActionInProgress])

    const handleClick = useCallback(async () => {
        if (!notification.$read) {
            setIsActionInProgress(true)
            try {
                await markAsRead(notification)
            } finally {
                setIsActionInProgress(false)
            }
        }
        history.push(notification.$url)
    }, [notification, markAsRead, history])

    return (
        <ListItem
            disablePadding
            divider
            secondaryAction={
                <>
                    <IconButton
                        aria-label={notification.$read ? 'Mark as unread' : 'Mark as read'}
                        onClick={handleMarkToggle}
                        disabled={isActionInProgress}
                        title={notification.$read ? 'Markeer als ongelezen' : 'Markeer als gelezen'}
                    >
                        {notification.$read ? <MarkReadIcon /> : <MarkUnreadIcon />}
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={handleDelete}
                        disabled={isActionInProgress}
                        title="Verwijder"
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            }
            style={{ backgroundColor: notification.$read ? 'inherit' : 'aliceblue' }}
            sx={{
                '& .MuiListItemSecondaryAction-root': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: { xs: 0.5, sm: 1 },
                    right: { xs: 4, sm: 8 },
                },
                '& .MuiListItemText-root': {
                    marginRight: { xs: '80px', sm: '100px' },
                }
            }}
        >
            <ListItemButton 
                onClick={handleClick}
                disabled={isActionInProgress}
            >
                <ListItemAvatar>
                    <Badge>
                        <NotificationLogo notification={notification} />
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    primary={notification.$title}
                    secondary={
                        <>
                            <NotificationText notification={notification} />
                            <NotificationTime date={notification.$createdAt} />
                        </>
                    }
                />
            </ListItemButton>
        </ListItem>
    )
}

export default CKNotification
