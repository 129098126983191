import { isEventRelated, NotificationType } from 'enums/NotificationType'
import EventNotification from './EventNotification'
import Notification from './Notification'

export function createNotificationFromJSON(json: {
    id: string
    type: string
    data: { title: string; message: string }
    read_at: string | null
    created_at: string
    extra_data?: any
}): Notification {
    const type = json.type as NotificationType
    if (isEventRelated(type)) {
        return EventNotification.fromJSON(json)
    } else {
        return Notification.fromJSON(json)
    }
}