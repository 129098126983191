import { notificationRegistry } from './NotificationTypes/registry'
import { FC } from 'react'
import Notification from 'models/Notifications/Notification'

interface NotificationComponentProps {
    notification: Notification
}

export const NotificationText: FC<NotificationComponentProps> = ({ notification }) => {
    const NotificationComponent = notificationRegistry[notification.$type] || notificationRegistry.default
    return <NotificationComponent.Text notification={notification} />
}

export const NotificationLogo: FC<NotificationComponentProps> = ({ notification }) => {
    const NotificationComponent = notificationRegistry[notification.$type] || notificationRegistry.default
    return <NotificationComponent.Logo notification={notification} />
} 