import { useTheme } from '@emotion/react'
import { Card, Grid } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import {
    CheckIcon,
    MicIcon,
    RadioButtonCheckedIcon,
    RadioButtonUncheckedIcon,
} from 'components/UI/CKIcons'
import { useUserContext } from 'contexts/UserContext'
import Event from 'models/Event'
import { FC, useState } from 'react'
import { useEvent } from 'services/EventService'
import EventSpotService from 'services/EventSpotService'
import { useIsMobile } from 'utils/ViewportUtils'

export interface IAvailableSpots {
    event: Event
}

export const AvailableSpots: FC<IAvailableSpots> = ({ event }: IAvailableSpots) => {
    const userContext = useUserContext()
    const theme = useTheme()
    const { mutate } = useEvent(event.$slug)
    const isMobile = useIsMobile()

    const orderedSpots = Array.from(event?.$spots).sort((a, b) => {
        if (b.$type === 'MC') {
            return -1
        }
        return a.$length - b.$length
    })

    const Spot = ({ spot }) => {
        const [applied, setApplied] = useState(spot.$applied)
        return (
            <div
                className="mb-3 d-flex justify-content-center"
                style={{ width: '100%', maxWidth: '600px' }}
            >
                <Card
                    className="p-3 d-flex flex-column align-items-center justify-content-between w-100"
                    style={{
                        backgroundColor: theme['palette'].info.main + '08',
                    }}
                >
                    <div>
                        <MKTypography fontWeight="bold" variant="body2" className="text-center">
                            {spot.$type === 'MC' ? 'Spot voor MC' : `Spot van ${spot.$length} min.`}
                        </MKTypography>
                        <MKTypography variant="button" className="p-0 pb-2">
                            {applied ? (
                                <>
                                    <CheckIcon className="me-2" />
                                    Je bent kandidaat
                                </>
                            ) : (
                                <>Je bent geen kandidaat</>
                            )}
                        </MKTypography>
                    </div>
                    <div className="mt-3">
                        <MKButton
                            variant="outlined"
                            color="info"
                            size="small"
                            className="ck-edit-button"
                            onClick={async () => {
                                let resp
                                if (!applied) {
                                    resp = await EventSpotService.apply(event, spot)
                                } else {
                                    resp = await EventSpotService.resign(event, spot)
                                }
                                mutate()
                                setApplied(resp.$applied)
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-center">
                                {applied ? (
                                    <>
                                        <RadioButtonUncheckedIcon
                                            className="me-2 ck-edit-icon"
                                            fontSize="large"
                                        />
                                        <p className="m-0 p-0">Trek in</p>
                                    </>
                                ) : (
                                    <>
                                        <RadioButtonCheckedIcon
                                            className="me-2 ck-edit-icon"
                                            fontSize="large"
                                        />
                                        <p className="m-0 p-0">Stel kandidaat</p>
                                    </>
                                )}
                            </div>
                        </MKButton>
                    </div>
                </Card>
            </div>
        )
    }

    return (
        <div>
            <MKTypography fontWeight="bold" variant="label" color="info">
                <div className="text-center mb-2">
                    <MicIcon className="me-2" /> Beschikbare spots
                </div>
            </MKTypography>
            <div className="d-flex align-items-center flex-column">
                <Grid container spacing={3} px={!isMobile && 3} className='justify-content-center'>
                    {orderedSpots &&
                        orderedSpots.map((spot) => {
                            if (spot.$type === 'MC' && !userContext.user.$isMC) return <></>
                            if (spot.$type === 'DEFAULT' && !userContext.user.$isOpenMicer)
                                return <></>
                            return (
                                <Grid item xs={12} md={4}>
                                    <Spot spot={spot} key={spot.$id} />
                                </Grid>
                            )
                        })}
                </Grid>
            </div>
        </div>
    )
}
