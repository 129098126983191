import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import { Container, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CKSearchBar from 'components/Form/CKSearchBar'
import { CKLoading } from 'components/Generic/CKLoading'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKLocationCompactCard } from 'components/UI/CKLocationCard'
import CKPagination from 'components/UI/CKPagination'
import { useUserContext } from 'contexts/UserContext'
import { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import UserService from 'services/UserService'

interface Props { }
const CKLocationManagement: FC<Props> = (props) => {
    const [locations, setLocations] = useState([])
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const [loading, setLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('')
    const [hasLocations, setHasLocations] = useState(false)
    const history = useHistory()
    const userContext = useUserContext()

    const perPage = useMemo(() => {
        if (isLarge) {
            return 8
        }
        return 5
    }, [isLarge])

    const [paginationInfo, setPaginationInfo] = useState<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
    }>()

    const init = async (page: number = 1, search = searchTerm) => {
        // setLoading(true);

        try {
            const _locations = await UserService.getManagedLocations(page, perPage, search)
            if (_locations.data.length > 0) {
                setHasLocations(true)
            }

            setLocations(_locations.data)

            delete _locations.data
            setPaginationInfo(_locations)
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const DashboardTitle = ({ children }) => {
        return (
            <MKTypography
                variant="h3"
                color="info"
                mt={3}
                className="text-center ck-dashboard-title"
                textGradient
            >
                {children}
            </MKTypography>
        )
    }

    return (
        <>
            {Boolean(hasLocations || (userContext.user && userContext.user.$isOrganizer)) && (
                <Container className="d-flex flex-column p-0 mt-3" style={{ flex: '1 1 auto' }}>
                    <div className="mt-2">
                        <Grid
                            container
                            className="mt-1 justify-content-center"
                            style={{ flex: '1 1 auto' }}
                        >
                            {loading ? (
                                <CKLoading />
                            ) : (
                                <>
                                    <Grid item xs={12} className="text-center">
                                        <MKButton
                                            color="info"
                                            variant="outlined"
                                            onClick={(e) => {
                                                if (e.ctrlKey) {
                                                    window.open('admin/location/new', '_blank')
                                                } else {
                                                    history.push('admin/location/new')
                                                }
                                            }}
                                        >
                                            <AddBusinessIcon className="me-2" /> Locatie aanmaken
                                        </MKButton>
                                        {hasLocations && (
                                            <CKSearchBar
                                                className="my-4"
                                                onSearch={(e) => {
                                                    setSearchTerm(e)
                                                    init(1, e)
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    {!locations ||
                                        (locations.length === 0 && (
                                            <div className="w-100 d-flex justify-content-center align-items-center flex-column my-5 py-4 h-100">
                                                <MKTypography fontWeight="bold" className="mt-2">
                                                    Geen locaties gevonden...
                                                </MKTypography>
                                            </div>
                                        ))}
                                    {locations && (
                                        <>
                                            {locations.map((location, index) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        lg={4}
                                                        key={'location-' + location.$id}
                                                        className="pt-1 px-2 pt-lg-2"
                                                    >
                                                        <CKLocationCompactCard
                                                            location={location}
                                                            useAdminUrl
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                        </>
                                    )}
                                    <CKPagination
                                        info={paginationInfo}
                                        onSelect={(page) => init(page)}
                                    />
                                </>
                            )}
                        </Grid>
                    </div>
                </Container>
            )}
        </>
    )
}
export default CKLocationManagement
