import { Container } from '@mui/material'
import { CKHero } from 'components/UI/CKHero'
import { CKPageContent } from 'components/UI/CKPageContent'
import { FC } from 'react'

export interface INotificationPageContainer {
    children: any
    loading: boolean
}

export const NotificationPageContainer: FC<INotificationPageContainer> = ({ children }) => {
    return (
        <>
            <CKHero size="md" title="Notificaties" />
            <CKPageContent>
                <Container>{children}</Container>
            </CKPageContent>
        </>
    )
}
