import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import MKButton from 'components/MaterialKit/MKButton'
import { useDarkMode, useDarkModeDispatch } from 'contexts/DarkModeContext'
import { FC, useState } from 'react'
import './style.scss'

interface Props {
    variant?: string
}

const CKDarkModeToggle: FC<Props> = (props) => {
    const darkMode = useDarkMode()
    const isDarkDispatch = useDarkModeDispatch()

    const [style, setStyle] = useState(props.variant ? props.variant : 'button')

    const toggleMode = () => {
        isDarkDispatch({
            type: 'toggle',
            value: !darkMode.dark,
        })
    }

    return (
        <>
            {style === 'button' && (
                <MKButton className="ms-2 p-0 ck-dark-mode-toggle" onClick={toggleMode} iconOnly>
                    {!darkMode.dark ? <DarkModeIcon /> : <LightModeIcon />}
                </MKButton>
            )}
            {style === 'button-text' && (
                <MKButton
                    className="ms-2 p-0 ck-dark-mode-toggle"
                    onClick={toggleMode}
                    // iconOnly
                >
                    {!darkMode.dark ? (
                        <>
                            <DarkModeIcon className="me-2" /> Dark mode
                        </>
                    ) : (
                        <>
                            <LightModeIcon className="me-2" /> Light mode
                        </>
                    )}
                </MKButton>
            )}
        </>
    )
}
export default CKDarkModeToggle
