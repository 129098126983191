import { Zoom, toast } from 'react-toastify'
import { mutate } from 'swr'
import Notification from 'models/Notifications/Notification'
import { createNotificationFromJSON } from 'models/Notifications/NotificationFactory'
import CKIndexProps from 'utils/CKIndexProps'
import AuthService from './AuthService'
import { NOTIFICATIONS } from './Constants'

const showError = (message = 'Er ging iets mis') => {
    toast.error(message, {
        containerId: 'B',
        transition: Zoom,
    })
}

const mutateNotifications = () => {
    mutate(`${NOTIFICATIONS}`)
    mutate(`${NOTIFICATIONS}/unread-count`)
}

const NotificationService = {
    index: async (data: CKIndexProps) => {
        const resp = await AuthService.get(`${NOTIFICATIONS}`, data)
        const notifications = resp.data.data.map((json: any) => createNotificationFromJSON(json))
        return { ...resp.data, data: notifications }
    },

    unreadCount: async () => {
        const resp = await AuthService.get(`${NOTIFICATIONS}/unread-count`)
        return resp.data
    },

    markAllAsRead: async () => {
        try {
            await AuthService.put(`${NOTIFICATIONS}/mark-all-as-read`, {})
            mutateNotifications()
        } catch (e) {
            console.error(e)
            showError()
            throw e
        }
    },

    markAsRead: async (notification: Notification) => {
        notification.toggleRead()
        try {
            await AuthService.put(`${NOTIFICATIONS}/${notification.$id}/mark-as-read`, {})
            mutateNotifications()
        } catch (e) {
            console.error(e)
            showError()
            notification.toggleRead() // Revert the optimistic update
            throw e
        }
    },

    markAsUnread: async (notification: Notification) => {
        notification.toggleRead()
        try {
            await AuthService.put(`${NOTIFICATIONS}/${notification.$id}/mark-as-unread`, {})
            mutateNotifications()
        } catch (e) {
            console.error(e)
            showError()
            notification.toggleRead() // Revert the optimistic update
            throw e
        }
    },

    delete: async (notification: Notification) => {
        try {
            await AuthService.delete(`${NOTIFICATIONS}/${notification.$id}`)
            mutateNotifications()
        } catch (e) {
            console.error(e)
            showError()
            throw e
        }
    },
}

export default NotificationService