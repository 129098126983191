import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useUserContextDispatch } from 'contexts/UserContext'
import { FC, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Zoom, toast } from 'react-toastify'
import UserService from 'services/UserService'

interface Props {}

const DeleteAccount: FC<Props> = () => {
    const [keyword, setKeyword] = useState<string>('')
    const [errors, setErrors] = useState({})
    const [deleting, setDeleting] = useState<boolean>(false)

    const userDispatch = useUserContextDispatch()

    const deleteAccount = async () => {
        setDeleting(true)

        if (!valid()) {
            setDeleting(false)
            return
        }

        try {
            await UserService.deleteMe()
            userDispatch({
                type: 'LOGOUT',
            })

            toast.success('Account verwijderd', {
                containerId: 'B',
                transition: Zoom,
            })

            window.location.href = '/'
        } catch (e) {
            console.error(e)
            toast.error('Er ging iets mis', {
                containerId: 'B',
                transition: Zoom,
            })
        }
        setDeleting(false)
    }

    const valid = () => {
        const _errors = {}

        if (keyword !== 'VERWIJDEREN') {
            _errors['keyword'] = 'Input is onjuist'
        }

        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }

    /**
     * Event handler on chat input
     * @param e
     */
    const handleTextInput = async (e) => {
        if (e.key === 'Enter') {
            deleteAccount()
        }
    }

    return (
        <>
            <MKBox mb={4}>
                <MKTypography variant="body2" mb={2}>
                    Heb je geen zin meer om te lachen? Type 'VERWIJDEREN' om je account definitief
                    te verwijderen van ComedyKit.
                </MKTypography>
                <MKTypography variant="body2">
                    <b>Let op: Deze actie is onomkeerbaar!</b>
                </MKTypography>
            </MKBox>
            <MKBox mb={4}>
                <MKInput
                    type="text"
                    label="Type 'VERWIJDEREN'"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    variant="outlined"
                    fullWidth
                    onKeyPress={handleTextInput}
                    error={errors.hasOwnProperty('keyword')}
                />
                <CKFormFeedback>{errors['keyword']}</CKFormFeedback>
            </MKBox>
            <MKBox mb={1}>
                <MKButton
                    variant="gradient"
                    color="error"
                    onClick={deleteAccount}
                    fullWidth
                    disabled={deleting}
                >
                    {deleting ? <ClipLoader size={15} color={'white'} /> : 'Verwijder account'}
                </MKButton>
            </MKBox>
        </>
    )
}
export default DeleteAccount
