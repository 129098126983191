import { Avatar } from '@mui/material'
import EventNotification from 'models/Notifications/EventNotification'
import { FC } from 'react'

interface EventNotificationLogoProps {
    notification: EventNotification
}

const EventNotificationLogo: FC<EventNotificationLogoProps> = ({ notification }) => {
    return (
        <Avatar
            alt={`Event '${notification.$event.$name}' logo`}
            src={notification.$event.$logo.$url}
        />
    )
}

export default EventNotificationLogo
