import { Card } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ErrorIcon, PDFIcon, TicketIcon } from 'components/UI/CKIcons'
import { CKModal, CKModalBody, CKModalHeader, CKModalTitle } from 'components/UI/CKModal'
import CKModalFooter from 'components/UI/CKModal/CKModalFooter'
import { useCKSearchParams } from 'contexts/SearchParamsContext'
import Event from 'models/Event'
import Ticket from 'models/Ticket'
import { FC, useMemo, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import AuthService from 'services/AuthService'
import { useMyEventTickets } from 'services/TicketService'

export interface IBoughtTicketsModal {
    isOpen?: boolean
    onClose?: () => void
    event: Event
    transaction?: string
}

export const BoughtTicketsModal: FC<IBoughtTicketsModal> = ({
    isOpen: isOpenProp,
    onClose: onCloseProp,
    event,
    transaction,
}) => {
    const [isOpen, setIsOpen] = useState(Boolean(transaction) ?? isOpenProp ?? false)
    const { hash: emailHash } = useCKSearchParams()

    const { tickets, isLoading } = useMyEventTickets({ event, transaction, hash: emailHash })
    const [isDownloading, setIsDownloading] = useState(false)

    const error = useMemo(() => {
        console.log(tickets)
        if (!isLoading && tickets.length === 0) {
            return true
        }
        return false
    }, [tickets, isLoading])

    useMemo(() => {
        console.log(isOpenProp)
        if (transaction) {
            setIsOpen(true)
        } else if (isOpenProp) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [isOpenProp, transaction])

    const TicketCard = ({ ticket }: { ticket: Ticket; index?: number }) => {
        return (
            <Card
                className="p-3 mb-3"
                style={{ backgroundColor: '#ffffff80' }}
                key={`ticket-${ticket.$id}`}
            >
                <div className="d-flex justify-content-between mb-2">
                    <MKTypography color="info" fontWeight="bold" variant="h6">
                        {ticket.$myBoughtAmount} x {ticket.$title}
                    </MKTypography>
                </div>
                <MKTypography variant="body2" className="mb-1" style={{ fontSize: '0.875rem' }}>
                    {ticket.$description}
                </MKTypography>
            </Card>
        )
    }

    const downloadTickets = () => {
        setIsDownloading(true)
        const eventSlug = event.$slug
        let url = `${process.env.REACT_APP_API_URL}events/${eventSlug}/tickets/pdf`
        if (emailHash) {
            url += `?hash=${emailHash}`
        }

        const anchor = document.createElement('a')
        document.body.appendChild(anchor)

        const headers = new Headers()
        headers.append('Authorization', `Bearer ${AuthService._getJWT()}`)

        fetch(url, { headers })
            .then((response) => response.blob())
            .then((blobby) => {
                const objectUrl = window.URL.createObjectURL(blobby)

                anchor.href = objectUrl
                anchor.download = `tickets-${event.$slug}.pdf`
                anchor.click()

                window.URL.revokeObjectURL(objectUrl)
                setIsDownloading(false)
            })
    }

    const onClose = () => {
        setIsOpen(false)
        onCloseProp?.()
    }

    const renderTickets = () => {
        const amountTickets: { [key: string]: number } = {}
        console.log(tickets)

        tickets.map((ticket) => {
            if (!amountTickets[ticket.$id]) {
                amountTickets[ticket.$id] = 0
            }
            amountTickets[ticket.$id] += 1
        })
        return Object.keys(amountTickets).map((ticketId) => {
            const ticket = tickets.find((ticket) => ticket.$id === ticketId)

            return <TicketCard ticket={ticket} key={`ticket-${ticket.$id}`} />
        })
    }

    return (
        <CKModal closeButton open={isOpen} onClose={onClose}>
            <CKModalHeader>
                <CKModalTitle>
                    <TicketIcon className="me-2" />
                    {error ? 'Er ging iets mis...' : 'Jouw tickets'}
                </CKModalTitle>
            </CKModalHeader>
            {!error ? (
                <>
                    <CKModalBody>
                        <div className="my-3">{renderTickets()}</div>
                    </CKModalBody>
                    <CKModalFooter>
                        <div className="text-center w-100">
                            <MKButton
                                color="info"
                                variant="contained"
                                fullWidth
                                style={{ maxWidth: '200px' }}
                                className="py-2 px-3"
                                onClick={() => downloadTickets()}
                            >
                                {isDownloading ? (
                                    <ClipLoader size={15} color="white" className="me-2" />
                                ) : (
                                    <PDFIcon className="me-2" />
                                )}
                                Download
                            </MKButton>
                        </div>
                    </CKModalFooter>
                </>
            ) : (
                <CKModalBody style={{ maxHeight: '400px', height: '100vh' }} className="px-3">
                    <div className="text-center d-flex h-100 align-items-center justify-content-center flex-column">
                        <ErrorIcon color="error" fontSize="large" className="mb-3" />
                        <MKTypography variant="body2" color="error">
                            Er ging iets mis bij het verifiëren van jouw transactie.
                        </MKTypography>
                        <MKTypography variant="body2" color="error">
                            Controlleer of de betaling is uitgevoerd en neem indien nodig contact op
                            met ComedyKit.
                        </MKTypography>
                    </div>
                </CKModalBody>
            )}
        </CKModal>
    )
}
